import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";

class AnalyticsStore {
  state = {
    all_requesters: [],
    top_songs: [],
    top_requesters: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getAllRequesters = async (start, end) => {
    const res = await get_query(
      `/analytics/allEmailsList/?${start && `date__start=${start}`}&${
        end && `date__end=${end + "T23:59:59"}`
      }`
    );

    if (res.code === 200) {
      this.state.all_requesters = res.data;
    } else {
      toast.error("Error while fetching analytics");
      return null;
    }
  };

  getTopSongs = async (start = "", end = "") => {
    const res = await get_query(
      `/analytics/mostRequestedSongs/?${start && `date__start=${start}`}&${
        end && `date__end=${end + "T23:59:59"}`
      }`
    );
    if (res.code === 200) {
      this.state.top_songs = res.data;

      const myData = this?.state?.top_songs.map((ele) => {
        console.log(ele, ele?.song?.title, ele.count, "eleleleleelelelel");
        return { name: ele?.song?.title, value: ele?.count };
      });

      console.log(myData, "Datatatatata");
      return myData;
    } else {
      toast.error("Error while fetching analytics");
      return null;
    }
  };

  getTopRequesters = async (start = "", end = "") => {
    const res = await get_query(
      `/analytics/topAudienceEmails/?${start && `date__start=${start}`}&${
        end && `date__end=${end + "T23:59:59"}`
      }`
    );
    if (res.code === 200) {
      this.state.top_requesters = res.data;
    } else {
      toast.error("Error while fetching analytics");
      return null;
    }
  };
}

const analyticsStore = new AnalyticsStore();
export default analyticsStore;
