import React from "react";
import ToggleButton from "../ToggleButton";
import playlistStore from "../../store/playlist";
import { toast } from "react-toastify";

const PlaylistItem = ({
  playlist: item,
  showBorder,
  editPlaylistStatus,
  setPlaylist,
  setEdit,
  setDeletion,
  setShow,
  isRed,
  isAllowed,
  key,
}) => {
  const {
    editPlaylist,
    state: { playlist: selected },
  } = playlistStore;
  const toggleActive = () => {
    editPlaylistStatus(item?.playlistId);
  };

  const handleEdit = (data, id) => {
    editPlaylist(data, id);
  };

  const editPlayList = () => {
    setEdit(true);
  };

  return (
    <div
      key={item.playlistId}
      className=" border border-[#303947]  grid grid-cols-12 gap-1 items-center "
      // onClick={() => alert(JSON.stringify(selected))}
    >
      <h5
        class={`col-span-6 p-2  sm:text-md text-sm  font-semibold cursor-pointer ${
          isRed ? "text-red" : "text-white"
        }`}
        onClick={() => {
          setShow("selected");
          if (!isAllowed) {
            toast.error("Please Activate Your Subscription! ");
          } else {
            setPlaylist(item);
          }
        }}
        colSpan={4}
      >
        {item?.title}
      </h5>
      <p class=" col-span-2 p-2  text-white flex gap-1 items-center text-center">
        <img
          onClick={() => {
            if (isAllowed) {
              setEdit(item);
            } else {
              toast.error("Please Aactivate You Subscription !");
            }
          }}
          src={`${process.env.PUBLIC_URL}/assets/icons/update-icon.svg`}
          height={36}
          width={36}
          className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
        />

        <img
          onClick={() => {
            if (isAllowed) {
              setDeletion(item?.playlistId);
            } else {
              toast.error("Please Aactivate You Subscription !");
            }
          }}
          src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
          height={36}
          width={36}
          className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
        />
      </p>
      <p class="p-2 text-white col-span-4 flex justify-center">
        <ToggleButton
          toggle={item?.status === "active"}
          handleClick={toggleActive}
        />
      </p>
    </div>
  );
};

export default PlaylistItem;
