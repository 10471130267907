import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import AppRouter from "./router";
import Layout from "./component/layout";
import Loading from "./component/layout/Loading";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import authStore from "./store/auth";
import { observer } from "mobx-react";
import setAuthToken from "./utils/setAuthToken";

setAuthToken(localStorage.getItem("x-auth-token"));

const App = () => {
  setAuthToken(localStorage.getItem("x-auth-token"));
  useEffect(() => {
    if (localStorage.getItem("x-auth-token")) {
      authStore.loadUser();
    } else {
      authStore.logout();
    }
  }, [authStore.state.isAuthenticated]);

  return (
    <BrowserRouter>
      <Layout>
        <AppRouter />
        <Loading />
        <ToastContainer
          pauseOnFocusLoss={false}
          autoClose={1500}
          theme="dark"
          closeOnClick
        />
      </Layout>
    </BrowserRouter>
  );
};

export default observer(App);
