import React from "react";

const About_03_Card = ({ index, item }) => {
  return (
    <li
      key={index}
      className="col-span-6 text-[12px] md:text-[14px] backdrop-blur-11 border-2 rounded border-[#707070]   bg-[#22252a] bg-opacity-50 flex flex-col items-start sm:p-6 p-3 gap-5"
      // style={{ backdropFilter: 11 }}
    >
      <img
        src={item?.image}
        height={50}
        width={50}
        className="object-contain"
      />
      <div className="text-left">
        <h4 className="md:text-[20px] text-[12px] line-clamp-1 font-semibold">
          {index + 1}.{item?.title}
        </h4>
        <p className=" text-left line-clamp-3">{item?.description}</p>
      </div>
    </li>
  );
};

export default About_03_Card;
