import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import authStore from "../store/auth";

const ActivateUser = () => {
  const { activateUser } = authStore;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      activateUser({ token: token }).then((res) => {
        console.log(res, "This");
        res === 200 ? navigate("/settings") : navigate("/login");
      });
    }
  }, []);

  return (
    <div className="flex flex-col items-center">
      <p className="text-center mt-20 text-2xl">Activating your account...</p>
      <img
        src={process.env.PUBLIC_URL + "/assets/gifs/loading.gif"}
        alt="loading"
      />
    </div>
  );
};

export default ActivateUser;
