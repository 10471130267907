import React from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";

const CenterPopup = ({ children, dark, popupstyles = "", containerStyle }) => {
  return (
    <div
      className={
        "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 z-30" +
        containerStyle
      }
    >
      <div
        className={`${
          dark ? "bg-[#272727] text-white" : "bg-white text-black"
        } rounded-lg shadow-lg max-w-xl w-[90%]  p-8 ${popupstyles}`}
      >
        {/* <button className="absolute top-0 right-0 m-4">
          <Link to="/">
            <IoIosArrowRoundForward />
          </Link>
        </button> */}
        {children}
      </div>
    </div>
  );
};

export default CenterPopup;
