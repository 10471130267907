import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import authStore from "../store/auth";
import { observer } from "mobx-react";

const PrivateRoute = () => {
  // const {
  //   state: { isAuthenticated },
  // } = authStore;

  return localStorage.getItem("x-auth-token") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default observer(PrivateRoute);
