import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import post_query from "../actions/post_query";
import put_query from "../actions/put_query";
import delete_query from "../actions/delete_query";

class CampaignStore {
  state = {
    campaigns: [],
    campaign: null,
    campaign_emails: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getCampaigns = async () => {
    const res = await get_query("/campaign/allUserCampaigns/");
    if (res.code === 200) {
      this.state.campaigns = res.data;
      if (res.data.length > 0) {
        this.setCampaign(res.data[0]);
      }
    } else {
      toast.error("Error while fetching campaigns");
    }
  };

  getEmails = async (date) => {
    const res = await get_query(
      date ? `/request/allEmailsByDate?date=${date}` : "/request/allEmails"
    );
    if (res.code === 200) {
      return res.data;
    } else {
      toast.error("Error while fetching emails");
    }
  };

  getEmailsByCampaign = async (id) => {
    const res = await get_query("/campaign/allParticipants/" + id);
    if (res.code === 200) {
      this.state.campaign_emails = res.data;
    } else {
      toast.error("Error while fetching emails");
    }
  };

  setCampaign = (data) => {
    this.state.campaign = data;
    this.getEmailsByCampaign(data.campaignId);
  };

  addCampaign = async (data) => {
    const res = await post_query("/campaign/add", data);

    if (res.code === 200) {
      this.state.campaigns.push(res.data);
      this.setCampaign(res.data);
      toast.success("Campaign added successfully");
    } else {
      toast.error("Error while adding campaign");
    }
  };

  addCampaignEmail = async (data) => {
    const res = await post_query("/campaign/addParticipants", data);

    if (res.code === 200) {
      let array = res.data?.participants;

      this.getEmailsByCampaign(data.campaignId);
      // toast.success(JSON.stringify(this.state.campaign_emails.length));
      toast.success("Emails Aaddess Success fully");
    } else {
      toast.error("Error while adding email");
    }
  };

  removeCampaignEmail = async (id, email) => {
    const res = await delete_query("/campaign/removeParticipant/" + id);

    if (res.code === 200) {
      this.state.campaign_emails = this.state.campaign_emails.filter(
        (x) => x.email !== email
      );
    } else {
      toast.error("Error while removing email");
    }
  };

  editCampaign = async (data, id) => {
    const res = await put_query("/campaign/edit/" + id, data);

    if (res.code === 200) {
      this.state.campaigns = this.state.campaigns.map((x) =>
        x.campaignId === id ? { ...x, ...data } : x
      );
      this.state.campaign = { ...this.state.campaign, ...data };
      toast.success("Campaign updated successfully");
    } else {
      toast.error("Error while editing campaign");
    }
  };

  editCampaignStatus = async (id) => {
    const res = await put_query("/campaign/activeOrInactive/" + id);

    if (res.code === 200) {
      this.state.campaigns = this.state.campaigns.map((x) =>
        x.campaignId === id ? res.data : x
      );
      this.state.campaign = res.data;
    } else {
      toast.error("Error while editing campaign");
    }
  };

  deleteCampaign = async (id) => {
    const res = await delete_query("/campaign/delete/" + id);
    if (res.code === 200) {
      this.state.campaigns = this.state.campaigns.filter(
        (x) => x.campaignId !== id
      );
      this.state.campaign_emails = [];

      toast.success("Campaign deleted successfully");
      if (this.state.campaign?.campaignId === id) {
        this.state.campaign = null;
      }
    } else {
      toast.error("Error while deleting campaign");
    }
  };
}

const campaignStore = new CampaignStore();
export default campaignStore;
