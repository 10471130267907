import React, { useEffect, useState } from "react";
import PreviousRequestItem from "./PreviousRequestItem";
import { MdOutlineCancel } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { IoReloadSharp } from "react-icons/io5";
import moment from "moment";
const PreviousRequestList = ({
  data,
  selectedDate,
  setSelecetedDate,
  setTotal,
}) => {
  const [filteredData, setFilteredData] = useState(data || []);
  const [showFilter, setShowFilter] = useState(false);

  const filterByDate = () => {
    if (selectedDate) {
      let newData =
        data &&
        data.filter((item) => {
          const createdAtDate = new Date(item.createdAt);

          // alert(JSON.stringify(typeof selectedDate));
          // let date01 = moment(item.date).format("DD/MM/YYYY").split("/");
          let date01 = item.date.slice(0, 10).split("-");
          date01 = `${date01[2]}/${date01[1]}/${date01[0]}`;
          let date02 = String();
          date02 = `${
            selectedDate.getDate() > 9
              ? selectedDate.getDate()
              : "0" + selectedDate.getDate()
          }/${
            selectedDate.getMonth() + 1 > 9
              ? selectedDate.getMonth() + 1
              : "0" + (selectedDate.getMonth() + 1)
          }/${selectedDate.getFullYear()}`;

          // let date02 = new Date();
          // console.log(
          //   date01,
          //   date02,
          //   selectedDate.getDate(),
          //   selectedDate.getMonth(),
          //   selectedDate.getFullYear()
          // );

          return date01 == date02;
        });

      setTotal(newData.length);
      setFilteredData(newData);
    } else {
      setFilteredData(data);
      setTotal(data.length);
    }
  };

  useEffect(() => {
    filterByDate();
  }, [selectedDate]);

  return (
    <div className="mt-10 px-4 my-4 h-[90vh] overflow-y-auto">
      {/* {showFilter ? (
        <div className="flex gap-5 items-center mb-5">
          <input
            className="border-2 border-red rounded px-4 py-2 bg-transparent"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <button
            className="text-red text-xl"
            onClick={() => {
              setDate("");
              setShowFilter(false);
            }}
          >
            <MdOutlineCancel />
          </button>
        </div>
      ) : (
        <button
          className="flex gap-3 text-red items-center underline mb-5"
          onClick={() => setShowFilter(true)}
        >
          <BsCalendarDate /> Date Filter
        </button>
      )} */}
      {/* <section className="mb-2 px-2 text-2xl">
        <IoReloadSharp
          onClick={() => setSelecetedDate(null)}
          className={"cursor-pointer"}
        />
      </section> */}
      {filteredData.map((x, i) => (
        <PreviousRequestItem request={x} key={i} index={i} />
      ))}
    </div>
  );
};

export default PreviousRequestList;
