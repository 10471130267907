import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";

const Item = ({
  data,
  setCampaign,
  setEditData,
  deleteCampaign,
  setDeletion,
  isRed = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="relative ">
      <button
        onClick={() => setCampaign(data)}
        className={`px-2 sm:text-[20px] text-sm font-semibold py-4 w-full text-left border-y-grey/10 border-y-[1px] ${
          isRed ? "text-red" : "text-white"
        }`}
      >
        {data.name}
      </button>
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="absolute right-0 top-2 p-4"
      >
        <BsThreeDots />
      </button>
      {showMenu && (
        <div className="absolute z-50 right-10 top-8 flex flex-col bg-black drop-shadow w-32 rounded border-[1px] border-white">
          <button
            onClick={() => {
              setEditData(data);
              setShowMenu(false);
            }}
            className="py-2"
          >
            Edit
          </button>
          <button
            onClick={() => {
              setDeletion(data);
              setShowMenu(false);
            }}
            className="py-2 border-t-[1px] border-t-white"
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default Item;
