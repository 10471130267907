import React, { useEffect, useState } from "react";
import SongItem from "./SongItem";
import songStore from "../../store/song";
import authStore from "../../store/auth";
import { observer } from "mobx-react";

const SongsList = ({ playlistId, filterSongs }) => {
  // const {
  //   state: { songs },
  //   getActiveSongs,
  // } = songStore;

  const {
    state: { songs, song },
    getActiveSongs,
    setSong,
    addSong,
  } = songStore;
  // const {
  //   state: { user },
  // } = authStore;

  // const [filteredSongs, setFilteredSongs] = useState([]);

  // useEffect(() => {
  //   user && getActiveSongs(user.userId);
  // }, [user]);

  // useEffect(() => {
  //   setFilteredSongs((filteredSongs) =>
  //     songs.filter((x) => x.playlistId === playlistId)
  //   );
  // }, [songs, playlistId]);

  return (
    <div>
      {filterSongs.length >= 1 && (
        <div
          className="grid grid-cols-12 gap-3 border bg-[#1E2126] border-[#303947] rounded p-2
        sm:text-[14px] text-xs
        "
        >
          <h5 class="text-start  p-2 text-white col-span-3" colSpan={4}>
            Songs
          </h5>
          <h5 class="text-start p-2 text-white col-span-3 ">Artist</h5>
          <h5 class=" p-2 text-white col-span-6 text-end">Actions</h5>
        </div>
      )}
      {filterSongs.map((x, i) => (
        <SongItem key={i} showBorder={i > 0} song={x} />
      ))}
    </div>
  );
};

export default observer(SongsList);
