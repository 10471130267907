import { BASE_URL } from "../constants";
import axios from "axios";
import loadingStore from "../store/loading";

const put_query = async (endpoint, putData) => {
  loadingStore.setLoading(true);

  try {
    const res = await axios.put(BASE_URL + endpoint, putData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { code: 200, data: res.data };
  } catch (error) {
    return { code: error.response.status, data: error.response.data };
  } finally {
    loadingStore.setLoading(false);
  }
};

export default put_query;
