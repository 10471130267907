import React, { useState } from "react";
import userStore from "../store/user";

const Contact = () => {
  const { contact } = userStore;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  let images = [
    `${process.env.PUBLIC_URL}/assets/images/search-changed-bg.png`,
    `${process.env.PUBLIC_URL}/assets/images/Contact_form.jpg`,
  ];
  const onSubmit = (e) => {
    e.preventDefault();

    contact(formData);
  };

  return (
    <div
      className="min-h-screen w-[100%] pb-10 flex items-center justify-center"
      style={{
        backgroundImage: `url(${images[0]})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "luminosity",
        borderRadius: "0.625rem 0 0 0.625rem",
        border: "1px solid #303947",
        // opacity: 0.64,
      }}
    >
      <div className="max-w-[1110px] w-[92%] mx-auto flex  lg:items-stretch items-center justify-center  mt-10 h-[600px] rounded-tl-lg rounded-bl-lg">
        <div className=" hidden lg:block flex-1">
          <img
            src={images[1]}
            className="w-full h-full object-cover rounded-tl-lg rounded-bl-lg"
          />
        </div>
        <form
          onSubmit={onSubmit}
          className="flex flex-col items-start gap-3 bg-[#22252A] md:p-10 p-5   justify-center lg:rounded-tr-lg lg:rounded-br-lg lg:flex-1 max-w-[600px] w-[96%]
           rounded-lg
          "
        >
          <div className="flex flex-col items-center gap-2 mb-4  w-full">
            <h1 className="mt-5 lg:text-4xl text-2xl uppercase font-bold">
              Contact us
            </h1>
            <p className="lg:text-[15px] text-[12px]">
              Let us know if you have any questions
            </p>
          </div>
          <div className="w-full lg:text-[14px] text-[12px]">
            <label htmlFor="name" className="text-[14px] font-bold mb-1.5">
              Name:
            </label>
            <input
              placeholder="Input your name"
              name="name"
              id="name"
              value={name}
              onChange={onChange}
              className="sm:w-full w-[100%] border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-gray-200 placeholder:font-medium placeholder:text-[#616161]"
            />
          </div>
          <div className="w-full">
            <label htmlFor="email" className="text-[14px] font-bold mb-1.5">
              Email ID:
            </label>
            <input
              placeholder="input email address"
              name="email"
              id="email"
              value={email}
              onChange={onChange}
              className="sm:w-full w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-gray-200 placeholder:font-medium placeholder:text-[#616161]"
            />
          </div>
          <div className="w-full">
            <label htmlFor="subject" className="text-[14px] font-bold mb-1.5">
              Subject:
            </label>
            <input
              placeholder="input subject"
              name="subject"
              id="subject"
              value={subject}
              onChange={onChange}
              className="sm:w-full w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-gray-200 placeholder:font-medium placeholder:text-[#616161]"
            />
          </div>
          <div className="w-full">
            <label htmlFor="message" className="text-[14px] font-bold mb-1.5">
              Message:
            </label>
            <textarea
              placeholder="input message"
              name="message"
              id="message"
              value={message}
              onChange={onChange}
              className="border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-gray-200 placeholder:font-medium resize-none w-full h-[100px] placeholder:text-[#616161]"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-red rounded w-full px-6 py-2  mt-2"
          >
            Send message
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
