import React, { useState, Fragment, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoIosArrowDown } from "react-icons/io";

import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import analyticsStore from "../store/analytics";
import { observer } from "mobx-react";
import ExportCSVButton from "../component/layout/ExportCsvButton";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import Audience_Data_01 from "../component/audience/Audience_Data_01";
import Audience_Data_02 from "../component/audience/Audience_Data_02";
import Audience_Data_03 from "../component/audience/Audience_Data_03";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authStore from "../store/auth";

const AudienceData = () => {
  const {
    state: { all_requesters, top_songs, top_requesters },
    getAllRequesters,
    getTopSongs,
    getTopRequesters,
  } = analyticsStore;

  const {
    state: { user },
  } = authStore;

  const navigate = useNavigate();
  useEffect(() => {
    if (user && !user?.subscription_active) {
      toast.error("Please activate your subscription!");
      navigate("/settings", { state: { myValue: "value" } });
    }
  }, [user]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [date, setDate] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [graphData, setGraphData] = useState([]);

  // const [date, setDate] = useState("");
  useEffect(() => {
    getAllRequesters();
    // getTopSongs();
    getTopRequesters();
  }, []);

  const handleSlideChange = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const handleSlideChangeBack = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  let icons = process.env.PUBLIC_URL + "/assets/icons/arrow.svg";
  return (
    <div>
      <div
        style={{ position: "relative" }}
        className=" relative block sm:hidden"
      >
        <Swiper
          direction={"vertical"}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChangeBack}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next", // Define next arrow element
            prevEl: ".swiper-button-prev", // Define previous arrow element
            disabledClass: "swiper-button-disabled", // Disabled class
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Audience_Data_01 data={all_requesters} />
          </SwiperSlide>
          <SwiperSlide>
            <Audience_Data_02
              graphData={graphData}
              setGraphData={setGraphData}
              date={date}
              setDate={setDate}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Audience_Data_03 top_requesters={top_requesters} />
          </SwiperSlide>
          {/* <Pagination */}
        </Swiper>

        {/* Arrow elements with icons */}
        <div className="absolute   z-50 sm:right-14  bottom-2   -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-prev hover:bg-red rotate-180"
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide - 1)}
          />
        </div>
        <div className="absolute sm:bottom-2 z-50 sm:right-2  right-2 bottom-2 -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-next hover:bg-red "
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide + 1)}
          />
        </div>
      </div>

      <div
        style={{ position: "relative" }}
        className=" relative hidden sm:block"
      >
        <Swiper
          direction={"vertical"}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChangeBack}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next", // Define next arrow element
            prevEl: ".swiper-button-prev", // Define previous arrow element
            disabledClass: "swiper-button-disabled", // Disabled class
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Audience_Data_01 data={all_requesters} />
          </SwiperSlide>
          <SwiperSlide>
            <Audience_Data_02
              graphData={graphData}
              setGraphData={setGraphData}
              date={date}
              setDate={setDate}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Audience_Data_03 top_requesters={top_requesters} />
          </SwiperSlide>
          {/* <Pagination */}
        </Swiper>

        {/* Arrow elements with icons */}
        <div className="absolute   z-50 sm:right-14  bottom-2   -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-prev hover:bg-red rotate-180"
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide - 1)}
          />
        </div>
        <div className="absolute sm:bottom-2 z-50 sm:right-2  right-2 bottom-2 -rotate-90 sm:rotate-0">
          <img
            alt="logo"
            className="w-22 object-cover swiper-button-next hover:bg-red "
            src={icons}
            onClick={(e) => setCurrentSlide((slide) => slide + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(AudienceData);
