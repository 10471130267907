import React from "react";

const About_02 = ({ image }) => {
  return (
    <div
      className="h-screen flex justify-center items-center w-full"
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className="  flex flex-col gap-6 mx-auto text-white xl:w-[990px] md:w-[700px] sm:w-[600px] w-[92%] md:text-[16px] text-[14px]">
        <h2 className="xxl:text-6xl md:text-5xl text-3xl font-[600]">
          Our Mission Statement
        </h2>
        <p className="text-center text-[14px] sm:text-xl lg:text-2xl xxl:text-3xl">
          Facilitate meaningful connections between local musicians and their
          fanbase while fostering organic growth through data-driven insights.
        </p>
      </div>
    </div>
  );
};

export default About_02;
