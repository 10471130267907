import React, { useState } from "react";
import { useEffect } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Popup = ({ children, dark, popupstyles = "", to, setFunc }) => {
  const [popupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    if (popupOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
  }, [popupOpen]);

  return (
    <div
      className={` bg-black/50 fixed top-0 left-0  h-screen w-screen z-30 flex items-center justify-end ${popupstyles}`}
    >
      <div
        className={`relative h-screen bg-[#22252A] ${
          popupOpen ? "opacity-90" : "opacity-0"
        }`}
        onClick={() => setFunc()}
      >
        <button
          className="absolute -left-[30px]  top-5 bg-[#ff3838] py-2 pl-1 pr-1 z-50 rounded-s-full	"
          onClick={() => setFunc()}
        >
          <IoIosArrowRoundForward size={24} />
        </button>
      </div>
      <div
        className={`lg:w-[650px]  sm:w-[500px] w-[95%] ${
          dark ? "bg-[#272727]" : "bg-white"
        } text-black rounded-lg  h-screen z-1 ${
          popupstyles ? popupstyles : "p-7"
        }`}
        style={{ display: popupOpen ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Popup;
