import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import subscriptionStore from "../store/subscription";
import userStore from "../store/user";
import { observer } from "mobx-react";

const PaymentSuccess = () => {
  const { addSubscription } = subscriptionStore;
  const {
    state: { user },
  } = userStore;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const session_id = searchParams.get("session_id");

    if (user) {
      addSubscription({ session_id: session_id }).then((res) =>
        console.log(res)
      );
    }
  }, [user]);

  return (
    <div className="flex justify-center items-center flex-col gap-10 mt-10">
      {/* <p className="text-8xl">👍</p> */}
      <p className="text-3xl">Payment has been successful</p>
      <button className="bg-red rounded-lg text-white px-4 py-2">
        <Link to="/playlist">Go to playlists</Link>
      </button>
    </div>
  );
};

export default observer(PaymentSuccess);
