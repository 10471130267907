import React, { useEffect, useRef, useState } from "react";

import LiveRequestList from "../component/requests/LiveRequestList";
import PreviousRequestList from "../component/requests/PreviousRequestList";

import requestStore from "../store/request";
import authStore from "../store/auth";
import { observer } from "mobx-react";

import io from "socket.io-client";
import { SERVER } from "../constants";
import { MdOutlineDateRange } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { RxCross1 } from "react-icons/rx";
const LiveRequests = () => {
  const {
    state: { requests },
    getRequests,
    acceptRequest,
    rejectRequest,
    getAllRequests,
    removeRequests,
  } = requestStore;
  const {
    state: { user },
  } = authStore;

  const [show, setShow] = useState("live");
  const [activeRequests, setActiveRequests] = useState([]);
  const [inactiveRequests, setInactiveRequests] = useState([]);
  const [total, setTotal] = useState(inactiveRequests.length);
  const [totalActive, setTotalActive] = useState(activeRequests.length);
  const inputRef = useRef();

  useEffect(() => {
    const socket = io(SERVER, { path: "/api/socket.io" });

    socket.on("getAllRequests", (data) => {
      user && getRequests(user.userId);

      console.log("socket Working");
    });

    user && getRequests(user.userId);

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    user && getRequests(user.userId);
    // user && getAllRequests(user.userId);
  }, [user]);

  const [date, setDate] = useState(null);

  useEffect(() => {
    setActiveRequests(requests?.filter((x) => x?.status === "active"));
    setInactiveRequests(requests?.filter((x) => x?.status !== "active"));
    setTotal(inactiveRequests.length);
    setTotalActive(activeRequests.length);
  }, []);
  useEffect(() => {
    setActiveRequests(requests?.filter((x) => x?.status === "active"));
    setInactiveRequests(requests?.filter((x) => x?.status !== "active"));
    setTotal(inactiveRequests.length);
    setTotalActive(activeRequests.length);
  }, [requests]);

  const handleAccept = async (id) => {
    acceptRequest(id);
  };

  const handleReject = async (id) => {
    rejectRequest(id);
  };

  const IgnoreRequests = (ids) => {
    const data = ids?.map((ele) => ele?.requestId);
    const obj = {
      requestIds: data,
    };
    setTotalActive(activeRequests.length - data.length);
    removeRequests(obj, user.userId);
  };

  return (
    <section className="mt-12  px-4 min-h-screen flex flex-col gap-3 mb-10 lg:w-[90%] w-[92%]  mx-auto">
      <h2 className="sm:text-4xl text-2xl font-semibold ">Live Request</h2>

      <section
        className="bg-[#22252A] border-[#303947]  gap-1 rounded items-center
        
      "
      >
        {/* Header of Live Request  */}

        <section className="flex sm:flex-row flex-col    sm:items-center  justify-between w-full p-3 border-[#303947] border rounded px-3">
          <h4 className="md:text-lg  sm:text-sm  xs:text-xs text-[8px]">
            {show === "previous" ? "Previous Requests" : "Live Requests"}{" "}
            <span className="text-red">(</span>
            {show === "previous" ? total : totalActive}
            <span className="text-red">)</span>{" "}
          </h4>

          <section className="flex  sm:gap-2 gap-1 items-center sm:mt-0 mt-3">
            {show === "previous" && (
              <div className="flex items-center gap-2">
                <p
                  className="p-2 bg-gray rounded-full cursor-pointer"
                  onClick={() => setDate(null)}
                >
                  <RxCross1 />
                </p>
                <div className="sm:order-1 order-2 flex items-center  p-2 border-[#303947] border relative">
                  <DatePicker
                    className="w-0 outline-none bg-black select-none focus:none appearance-none sm:text-lg xs:text-sm text-[8px]"
                    hidden
                    id="datepicker"
                    value={date}
                    selected={date}
                    ref={inputRef}
                    onChange={(e) => {
                      setDate(e);
                    }}
                  />

                  <label
                    htmlFor="datepicker"
                    className="md:text-lg  sm:text-sm  xs:text-xs text-[8px]"
                  >
                    {date
                      ? `${
                          date.getMonth() + 1
                        }/${date.getDate()}/${date.getFullYear()}`
                      : "Select Date"}
                  </label>
                  <MdOutlineDateRange
                    className="sm:ml-2 inline-block self-center  cursor-pointer "
                    onClick={() => {
                      if (inputRef?.current.isOpen) {
                        inputRef?.current.setOpen(false);
                      } else {
                        inputRef?.current.setOpen(true);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <section className="sm:order-2 order-1 flex text-md items-center bg-[#1B1E21] border-[#303947] border  rounded-lg p-1">
              <button
                className={`${
                  show === "live" ? "bg-red" : "bg-[#1B1E21]"
                } md:text-lg    xs:text-xs text-[8px] sm:px-6 xs:px-2 px-2 py-2 rounded-lg line-clamp-1`}
                onClick={() => setShow("live")}
              >
                Live Request
              </button>
              <button
                className={`${
                  show === "previous" ? "bg-red" : "bg-[#1B1E21]"
                }  md:text-lg   xs:text-xs text-[8px] sm:px-6 xs:px-2 px-2 py-2 rounded-lg`}
                onClick={() => setShow("previous")}
              >
                Previous Request
              </button>
            </section>
          </section>
        </section>

        {show === "live" && (
          <LiveRequestList
            data={activeRequests}
            handleAccept={handleAccept}
            handleReject={handleReject}
            deleteRequests={IgnoreRequests}
            setTotal={setTotalActive}
          />
        )}
        {show === "previous" && (
          <PreviousRequestList
            data={inactiveRequests}
            selectedDate={date}
            setSelecetedDate={setDate}
            setTotal={setTotal}
          />
        )}
      </section>
    </section>
  );
};

export default observer(LiveRequests);
