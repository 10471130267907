import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import delete_query from "../actions/delete_query";
import put_query from "../actions/put_query";
import post_query from "../actions/post_query";

class SongStore {
  state = {
    songs: [],
    song: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  getSongs = async (id) => {
    const res = await get_query("/song/allActive/" + id);
    if (res.code === 200) {
      this.state.songs = res.data;
    } else {
      toast.error("Error while fetching songs");
    }
  };

  getActiveSongs = async (id) => {
    const res = await get_query("/song/all/");
    if (res.code === 200) {
      this.state.songs = res.data;
    } else {
      toast.error("Error while fetching songs");
    }
  };

  getSongById = async (id) => {
    const res = await get_query("/song/getSong/" + id);
    if (res.code === 200) {
      return res.data;
    } else {
      toast.error("Error while fetching song");
    }
  };

  setSong = (data) => {
    this.state.song = data;
  };

  addSong = async (data) => {
    const res = await post_query("/song/add", data);

    if (res.code === 200) {
      this.state.songs = [...this.state.songs, res.data];
      toast.success("Song added successfully");
    } else {
      toast.error("Error while adding song");
    }
  };

  editSong = async (data, id) => {
    const res = await put_query("/song/edit/" + id, data);

    if (res.code === 200) {
      this.state.songs = this.state.songs.map((x) =>
        x.songId === id ? { ...x, ...data } : x
      );
      this.state.song = { ...this.state.song, ...data };
      toast.success("Song updated successfully");
    } else {
      toast.error("Error while editing song");
    }
  };

  deleteSong = async (id) => {
    const res = await delete_query("/song/delete/" + id);
    if (res.code === 200) {
      this.state.songs = this.state.songs.filter((x) => x.songId !== id);
      toast.success("Song deleted successfully");
    } else {
      toast.error("Error while deleting song");
    }
  };
}

const songStore = new SongStore();
export default songStore;
