import React from "react";

const About_01 = ({ image, title, description }) => {
  return (
    <div
      className="h-full flex justify-center w-full items-center min-h-full"
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className=" mx-auto text-white xl:w-[990px] md:w-[700px] sm:w-[600px] w-[92%] md:text-[16px] text-[14px]">
        <h2 className="xxl:text-6xl md:text-5xl text-3xl font-[600] mb-6">
          About Next<span className="font-[200] text-gray-300">Quest</span>
        </h2>
        <p className="text-center  text-[14px] sm:text-xl lg:text-2xl xxl:text-3xl ">
          Our mission is to help local artists spread their music to as many
          people as possible. Next Quest focuses on using data and analytics to
          help artists get an inside look into what their audience likes, what
          gigs they attended and allows them to send announcements to tailored
          audience members. Utilizing the key data that is collected helps build
          a more engaged fan base and is what sets us apart from our
          competition. Additionally, Next Quest lets local artists bring a
          personal connection at each gig. While you are playing, the audience
          is able to view your selected songs, have direct links to your social
          media and website, read more about you and send a tip using online
          payment forms. This allows you to grow a bigger following, be able to
          interact with the crowd and increase your tips.
        </p>
      </div>
    </div>
  );
};

export default About_01;
