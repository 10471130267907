import React, { useState } from "react";
import ThirdFlyer from "./ThirdFlyer";

const PrintAtHome = () => {
  const [tryDownload, setTryDownload] = useState(false);
  return (
    <section className="flex flex-col items-center gap-5 overflow-hidden">
      <ThirdFlyer enableState={tryDownload} setEnableState={setTryDownload} />

      <button
        className="bg-red p-2 rounded text-[16px] w-[330px]"
        onClick={() => setTryDownload(true)}
      >
        DOWNLOAD
      </button>
    </section>
  );
};

export default PrintAtHome;
