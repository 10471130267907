import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import moment from "moment";
const CampaignRightSide = ({
  campaign,
  campaigns,
  emailsDateFilter,
  campaign_emails,
  exportToCSV,
  setShowEmailForm,
  setEmailDeletion,
}) => {
  console.log(campaign_emails);
  return (
    <div className="bg-[#22252A] border-[#303947] border-2 rounded flex-3 h-[85vh] flex flex-col pb-3 flex-1 lg:col-span-7 col-span-12">
      {/* Upper Box  --- Search Bar Area*/}
      <div className="flex justify-between pb-2 items-center mb-4 p-3 py-5 border-[#303947] border-b-2">
        <h3 className="sm:text-2xl text-lg font-semibold">
          {campaign && campaign?.name.slice(0, 10)}
          {campaign && campaign.name?.length >= 12 && "..."}
        </h3>
        <div className="flex items-center gap-3">
          <button
            onClick={() => setShowEmailForm(true)}
            className={`sm:px-6 px-4 py-2 sm:text-lg text-xs  rounded-[6px]  ${
              campaign ? "bg-red text-white" : "text-[#5C5C5C] bg-[#323338]"
            }`}
          >
            Add Email
          </button>
          <button
            onClick={() =>
              exportToCSV(
                campaign_emails.map((x) => ({
                  name: x.name,
                  email: x.email,
                  date: moment.utc(x.createdAt).format("DD/MM/YYYY"),
                })),
                `${campaign.name} emails`
              )
            }
            // className="bg-[#23A071] w-[150px] rounded-lg py-2"
            className={`sm:px-6 px-4 py-2 sm:text-lg text-xs  rounded-[6px]  ${
              campaign
                ? "bg-[#23A071] text-white"
                : "text-[#5C5C5C] bg-[#323338]"
            }`}
          >
            Export to CSV
          </button>
        </div>
      </div>

      {/* Songs Rendering area  */}

      {campaign ? (
        <>
          {" "}
          <p className="my-5 px-2 text-white">{campaign?.description}</p>
          <div className="overflow-y-auto container-inner">
            {campaign_emails.length >= 1 && (
              <div className="md:text-base text-sm bg-[#1E2126] grid grid-cols-3 items-center border-[#303947] my-2 py-3 px-5 rounded-lg drop-shadow-lg mx-2">
                <p className="">Email</p>
                <p className="">
                  {/* {emailsDateFilter && emailsDateFilter?.toDateString()} */}
                </p>
                <p className="text-end p-2">Action</p>
              </div>
            )}

            {campaign_emails.map((x, i) => (
              <div
                key={i}
                className="md:text-base text-sm grid grid-cols-3 items-center bg-[#22252A] border-[#303947]  py-3 px-5 rounded-lg drop-shadow-lg mx-2 my-3"
              >
                <p className="">{x.email}</p>
                <p className=""></p>
                <p className="text-end">
                  <button
                    // onClick={() => handleToggleEmail({ email: x.email })}
                    onClick={() => setEmailDeletion(x.email)}
                    className="text-red bg-black p-3 rounded-lg drop-shadow-lg"
                  >
                    <FaRegTrashAlt />
                  </button>
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="h-full flex justify-center items-center font-primary">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/add_emails.svg"}
            className={"object-contain w-[80%]"}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignRightSide;
