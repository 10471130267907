import React, { useEffect, useRef, useState } from "react";
import Popup from "../layout/Popup";
import { MdOutlineDateRange } from "react-icons/md";
import { IoReloadSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import campaignStore from "../../store/campaign";
import moment from "moment";

const EmailForm = ({
  handleClose,
  emails,
  campaign,
  handleToggle,
  selected,
  emailsDateFilter,
  setEmailsDateFilter,
}) => {
  const inputRef = useRef();

  const [showingEmails, setShowingEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { addCampaignEmail } = campaignStore;

  useEffect(() => {
    setupList();
  }, [selectAll, emails]);

  useEffect(() => {
    if (emailsDateFilter) {
      let inputDate = new Date(emailsDateFilter);
      const isoString = inputDate.toISOString();
      const outputDate = isoString.slice(0, 10);

      let mails = [];
      if (showingEmails) {
        mails = showingEmails.filter(
          (request) => request?.createdAt.slice(0, 10) === outputDate
        );
      }
      setShowingEmails(mails);
    }
  }, [emailsDateFilter]);

  const setupList = () => {
    if (selectAll) {
      setShowingEmails(emails.map((ele) => ({ ...ele, status: true })));
    } else {
      const notInArray2 = emails.filter(
        (obj1) => !selected.some((obj2) => obj2.email === obj1.email)
      );
      const items = notInArray2.map((ele) => ({ ...ele, status: false }));
      setShowingEmails(items);
    }
  };

  const addEmails = (e) => {
    e.preventDefault();
    let mails = showingEmails
      .filter((ele) => ele?.status)
      .map((ele) => ({
        name: ele.name,
        email: ele.email,
      }));

    if (mails.length === 0) {
      setSelectAll(false);
      handleClose();
      return;
    }
    let obj = {
      campaignsData: mails,
      campaignId: campaign?.campaignId,
    };

    addCampaignEmail(obj);
    handleClose();
  };

  return (
    <Popup dark={true} popupstyles="p-0 m-0" setFunc={handleClose}>
      <form className="flex flex-col gap-5 h-[100%] sm:pb-5 pb-10 bg-[#22252A]">
        <div className="bg-black text-white w-full px-5 pt-12 pb-4 shadow-md border border-[#303947] flex justify-between">
          <h1 className="text-4xl font-bold ">Add Email</h1>
          <div className="flex items-center mr-2 p-2 border-[#303947] border relative z-50">
            <DatePicker
              className="w-0 outline-none bg-black select-none focus:none appearance-none"
              hidden
              id="datepicker"
              value={emailsDateFilter}
              selected={emailsDateFilter}
              ref={inputRef}
              onChange={(e) => setEmailsDateFilter(e)}
            />
            <label htmlFor="datepicker">Select Date</label>
            <MdOutlineDateRange
              className="ml-2 inline-block self-center  cursor-pointer"
              onClick={() => inputRef?.current.setOpen(true)}
            />
          </div>
        </div>
        <div className="flex-1 p-3 overflow-y-auto ">
          <section className="flex justify-between items-center text-white">
            <section>
              <p className="flex items-center gap-2 cursor-pointer">
                <input
                  type="checkbox"
                  style={{
                    accentColor: "red",
                  }}
                  checked={selectAll}
                  className="h-6 w-6 p-2  rounded cursor-pointer"
                  onChange={() => {
                    setSelectAll((select) => !select);
                  }}
                />
                <label htmlFor="check">All Select</label>{" "}
                <IoReloadSharp
                  color="gray"
                  className="cursor-pointer"
                  onClick={() => {
                    setEmailsDateFilter(null);
                    setSelectAll(false); // Reset selectAll state
                  }}
                />
              </p>
            </section>
            <p>{emailsDateFilter && moment.utc(emailsDateFilter).format("MM/DD/YYYY")}</p>
          </section>
          {showingEmails.map((x, i) => (
            <div
              onClick={() => {
                let mails = showingEmails.map((ele) => ({
                  ...ele,
                  status: ele.email === x.email ? !ele?.status : ele?.status,
                }));
                setShowingEmails(mails);
              }}
              key={i}
              className="flex items-center gap-3 my-2  py-3 px-5 rounded-lg drop-shadow-lg"
            >
              <input
                type="checkbox"
                style={{
                  accentColor: "red",
                  mixBlendMode: "multiply",
                }}
                checked={x?.status}
                className="h-6 w-6 p-2  rounded "
              />
              <label className="w-full text-white bg-[#22252A] border-[#303947] drop-shadow-md drop-shadow-[#0000005C] rounded p-2">
                {x.email}
              </label>
            </div>
          ))}
        </div>
        <button
          onClick={addEmails}
          className="border-red  bg-red text-white border-[3px] px-6 py-2 font-bold rounded mx-6"
        >
          ADD
        </button>
      </form>
    </Popup>
  );
};

export default EmailForm;
