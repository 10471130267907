import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import authStore from "../../store/auth";
import { useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

const Layout = ({ children }) => {
  const {
    state: { isAuthenticated, user },
  } = authStore;
  const logo = `${process.env.PUBLIC_URL}/assets/icons/logo-xl.png`;

  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  return (
    <div
      className={`bg-[#22252A] min-h-screen  grid grid-cols-12 xxl:grid-cols-9 ${
        location.pathname.includes("/audience-requests") && "grid-cold-12"
      } gap-0 flex flex-row w-full `}
    >
      {!location.pathname.includes("/audience-requests") && (
        <RxHamburgerMenu
          className={`lg:hidden absolute top-2 left-2 z-30 cursor-pointer ${
            toggle ? "hidden" : "block"
          }`}
          size={30}
          color="white"
          onClick={() => {
            setToggle(true);
          }}
        />
      )}
      <div
        className={` relative font-primary lg:block lg:col-span-2 xxl:col-span-1 z-[100000] ${
          toggle ? "block" : "hidden"
        } duration-500 ${
          location.pathname.includes("/audience-requests") &&
          "lg:hidden  hidden col-span-0 xxl:col-span-0"
        }
        } `}
      >
        <Navbar setToggle={setToggle} />
      </div>

      <div
        className={` bg-black lg:col-span-10  xxl:col-span-8   col-span-12 text-grey font-primary relative min-h-screen flex flex-row bg-no-repeat bg-cover ${
          location.pathname.includes("/audience-requests") &&
          "lg:col-span-12 col-span-12 xxl:col-span-9"
        }`}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Main_bg.png)`,
        }}
      >
        {/* <div className="lg:p-20 lg:pb-0 px-5 py-20 flex-1 flex flex-col"> */}
        <div className="flex-1 flex flex-col">
          {isAuthenticated &&
            location.pathname !== "/about" &&
            location.pathname !== "/contact" &&
            !location.pathname.includes("/audience-requests") && (
              <section className="absolute sm:right-4 right-1 top-1">
                <img
                  src={logo}
                  alt="Image is Missing"
                  className="sm:w-[140px] w-[100px]  sm:p-4 m-1 sm:m-0  "
                />
              </section>
            )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
