import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { MdOutlineDateRange } from "react-icons/md";
import moment from "moment";

import analyticsStore from "../../store/analytics";

const Audience_Data_02 = () => {
  const inputRef = useRef();

  const [viewClicked, setViewClicked] = useState(1);

  const {
    state: { top_songs },

    getTopSongs,
  } = analyticsStore;

  const [date, setDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState("");
  const [data, setData] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    Promise.resolve(
      getTopSongs(
        moment.utc(date).format("YYYY-MM-DD"),
        moment.utc(new Date()).format("YYYY-MM-DD")
      )
    )
      .then((array) => {
        setData(array);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);
  useEffect(() => {
    setIsOpen(false);

    Promise.resolve(
      getTopSongs(
        moment.utc(date).format("YYYY-MM-DD"),
        moment.utc(new Date()).format("YYYY-MM-DD")
      )
    )
      .then((array) => {
        setData(array);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [date]);

  // useEffect(() => {
  //   const myData = top_songs.map((ele) => {
  //     console.log(ele);
  //     return { name: ele?.song?.title, value: ele?.count };
  //   });

  //   console.log(myData, "this");
  //   setData(myData);
  // }, []);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip text-black">
          <p>{`${payload[0].payload.name}`}</p>
        </div>
      );
    }

    return null;
  };
  console.log(typeof data, data, "datatatat");
  return (
    <section className="min-h-full flex justify-center w-full items-center ">
      <section className="mx-auto text-white xxl:w-[1090px]  xl:w-[900px] md:w-[700px] sm:w-[600px] w-[90%]  flex flex-col gap-3 mt-10 items-center   border-[#485261]  px-4 backdrop-blur-11 opacity-1 py-4  sm:mt-0 ">
        <h3 className="sm:text-3xl text-xl font-semibold sm:mt-0 mt-5">
          Top Requested Songs
        </h3>
        <section className="w-full bg-[#22252A] backdrop-blur-11 backdrop-blur-[11px] bg-gray-900 bg-no-repeat bg-padding-box rounded-lg bg-opacity-10 border border-[#485261] custom-backdrop-filter">
          <section className="flex justify-end items-center border border-gray px-3 py-6 rounded-t sm:text-xl text-[10px]">
            {/* <h5 className="sm:text-[20px] text-xs">Most recently added</h5> */}

            <div className="sm:order-1 order-2 flex items-center  p-2 border-[#303947] border relative">
              <label
                htmlFor="datepicker"
                className="md:text-lg  sm:text-sm  xs:text-xs text-[8px]"
                onClick={toggleDropdown}
              >
                {date
                  ? moment.utc(date).format("MM/DD/YYYY")
                  : "Select a  Date"}
              </label>
              <MdOutlineDateRange
                className="sm:ml-2 inline-block self-center  cursor-pointer "
                onClick={toggleDropdown}
              />

              {isOpen && (
                <div
                  className="absolute -left-10 top-9 bg-white text-black
                flex flex-col  text-sm  rounded
                "
                >
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      const today = new Date();
                      const before7Days = new Date(today);
                      setDate(before7Days.setDate(today.getDate() - 7));
                    }}
                  >
                    Last Week
                  </span>
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() =>
                      setDate(
                        new Date(
                          new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                        )
                      )
                    }
                  >
                    Last Month
                  </span>
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      setDate(null);
                      setClicked("whole");
                    }}
                  >
                    Whole
                  </span>
                </div>
              )}
            </div>
          </section>
          <section className="flex flex-col ">
            <div className="p-3 flex justify-center">
              {top_songs?.length > 10 ? (
                <Fragment>
                  <div className=" w-full  text-white text-sm flex justify-center items-center">
                    <ResponsiveContainer width="90%" aspect={3}>
                      <BarChart
                        data={data.length && data?.slice(0, 10)}
                        width={400}
                        height={400}
                      >
                        <Bar dataKey={"value"} fill="red" />
                        <XAxis />
                        <YAxis />
                        {/* <CustomTooltip /> */}
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        {/* <Tooltip>
                          <p>{`Name: ${payload[0].payload.name}`}</p>
                          <p>{`Father Name: ${payload[0].payload.fatherName}`}</p>
                        </Tooltip> */}
                      </BarChart>
                    </ResponsiveContainer>
                    {/* <Bar
                        className="hidden md:block sm:w-[94%] w-full cursor-pointer sm:h-full h-[70%] text-white text-lg"
                        data={{
                          labels: top_songs
                            .slice(0, 10)
                            ?.map(
                              (data) =>
                                `${
                                  data.song.title.length > 20
                                    ? data.song.title.slice(0, 20)
                                    : data.song.title
                                }`
                            ),

                          datasets: [
                            {
                              label: "This Color Represents Songs",
                              data: top_songs?.map((data) => data.count),
                              backgroundColor: ["#FB2E33"],
                              borderRadius: 5,
                              borderColor: "#FB2E33",
                              color: "#FB2E33",

                              fontColor: "#fff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          scales: {
                            x: {
                              grid: {
                                display: false,
                              },
                            },
                          },
                          plugins: {},
                        }}
                      />

                      <Bar
                        className="block md:hidden sm:w-[94%] w-full cursor-pointer sm:h-full h-[70%] text-white text-lg"
                        data={{
                          labels: top_songs
                            .slice(0, 10)
                            ?.map((data) => `${data.song.title.slice(0, 10)}`),

                          datasets: [
                            {
                              label: "This Color Represents Songs",
                              data: top_songs?.map((data) => data.count),
                              backgroundColor: ["#FB2E33"],
                              borderRadius: 5,
                              borderColor: "#FB2E33",
                              color: "#FB2E33",

                              fontColor: "#fff",
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          scales: {
                            x: {
                              grid: {
                                display: false,
                              },
                            },
                          },
                          plugins: {},
                        }}
                      /> */}
                  </div>
                </Fragment>
              ) : (
                <div className="bg-gray rounded w-full p-8 my-5 sm:text-base text-xs">
                  <p className="text-center">
                    You will see your most requested songs after 10 requests.
                    Check back soon!
                  </p>
                </div>
              )}
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Audience_Data_02;
