import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import post_query from "../actions/post_query";

class UserStore {
  state = {
    users: [],
    user: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  getArtistById = async (id) => {
    const res = await get_query("/user/user/" + id);
    if (res.code === 200) {
      return res.data;
    } else {
      toast.error("Error while fetching artist");
      return null;
    }
  };

  getAllArtists = async (id) => {
    const res = await get_query("/user/allUsers");
    if (res.code === 200) {
      return res.data;
    } else {
      toast.error("Error while fetching artist");
      return null;
    }
  };

  getTopArtists = async (id) => {
    const res = await get_query("/analytics/allTopArtists");
    if (res.code === 200) {
      return res.data;
    } else {
      toast.error("Error while fetching artist");
      return null;
    }
  };

  contact = async (data) => {
    const res = await post_query("/user/ContactUs", data);
    if (res.code === 200) {
      toast.success("We will get back to you soon!");
    } else {
      toast.error("Error!");
    }
  };
}

const userStore = new UserStore();
export default userStore;
