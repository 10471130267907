import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import post_query from "../actions/post_query";
import put_query from "../actions/put_query";
import delete_query from "../actions/delete_query";

class PlaylistStore {
  state = {
    playlists: [],
    playlist: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  getPlaylists = async (userId) => {
    const res = await get_query("/playlist/allPlaylists/" + userId);
    if (res.code === 200) {
      this.state.playlists = res.data;
      this.setPlaylist(res.data[0]);
    } else {
      toast.error("Error while fetching playlists");
    }
  };

  setPlaylist = (data) => {
    this.state.playlist = data;
  };

  addPlaylist = async (data) => {
    const res = await post_query("/playlist/add", data);

    if (res.code === 200) {
      this.state.playlists.push(res.data);
      this.state.playlist = res.data;
      toast.success("Playlist added successfully");
    } else {
      if (res.code === 403) {
        toast.error("Please upgrade account to add a playlist !");
      } else {
        toast.error("Error While Creating a Playlist");
      }
    }
  };

  editPlaylist = async (data, id, no_msg) => {
    const res = await put_query("/playlist/edit/" + id, data);

    if (res.code === 200) {
      this.state.playlists = this.state.playlists.map((x) =>
        x.playlistId === id ? { ...x, ...data } : x
      );
      this.state.playlist = { ...this.state.playlist, ...data };
      !no_msg && toast.success("Playlist updated successfully");
    } else {
      toast.error("Error while editing playlist");
    }
  };

  editPlaylistStatus = async (id) => {
    const res = await put_query("/playlist/activeOrInactive/" + id);

    if (res.code === 200) {
      this.state.playlists = this.state.playlists.map((x) =>
        x.playlistId === id ? res.data : x
      );
      this.state.playlist = res.data;
    } else {
      toast.error("Error while editing playlist");
    }
  };

  deletePlaylist = async (id) => {
    const res = await delete_query("/playlist/delete/" + id);
    if (res.code === 200) {
      this.state.playlists = this.state.playlists.filter(
        (x) => x.playlistId !== id
      );
      toast.success("Playlist deleted successfully");
    } else {
      toast.error("Error while deleting playlist");
    }
  };
}

const playlistStore = new PlaylistStore();
export default playlistStore;
