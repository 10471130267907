import React, { useState } from "react";

const Artist = ({ formData, onChange, setSelected, onSubmit }) => {
  // const [artistInfo, setArtistInfo] = useState({
  //   bandName: "",
  //   bio: "",
  //   instagram: "",
  //   facebook: "",
  //   website: "",
  // });

  // const { bandName, bio, instagram, facebook, website } = artistInfo;

  // const onArtistChange = (e) =>
  //   setArtistInfo({ ...artistInfo, [e.target.name]: e.target.value });

  const { bio, instagram, facebook, website, bandName , facebookHandle , instaHandle} = formData;

  return (
    <section className="py-2 flex justify-center flex-col min-h-[90vh]">
      <div className="flex flex-col gap-4 border-[#303947] flex-1  bg-[#22252A] p-4">
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Band Name: </label>
          <input
            name="bandName"

            maxLength={30}
            value={bandName}
            onChange={onChange}
            placeholder="input brand name"
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className="text-[14px] flex flex-col gap-1">
        
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Bio: </label>
          <textarea
            maxLength={250}
            name="bio"
            placeholder="input bio"
            value={bio}
            onChange={onChange}
            className="resize-none flex-1 border-gray bg-[#323338] rounded p-3 outline-none "
          />
          <span className="text-[#7f8c8d]">Maximum length 250 Characters </span>
        </div>
        
        </div>
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Instagram Username: </label>
          <input
              name="instaHandle"
              value={instaHandle}
              placeholder="input instagram Username"
              onChange={onChange}
              className="border-gray bg-[#323338] rounded p-3 outline-none "
            />
        </div>
        
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Instagram Link: </label>
          <input
              name="instagram"
              value={instagram}
              placeholder="https://instagram.com/ "
              onChange={onChange}
              className="border-gray bg-[#323338] rounded p-3 outline-none "
            />
        </div>
      
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Facebook Username: </label>
          <input
              name="facebookHandle"
              value={facebookHandle}
              placeholder="input facebook Username"
              onChange={onChange}
              className="border-gray bg-[#323338] rounded p-3 outline-none "
            />
        </div>
        
        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Facebook Link: </label>
          <input
              name="facebook"
              value={facebook}
              placeholder="https://facebook.com/ "
              onChange={onChange}
              className="border-gray bg-[#323338] rounded p-3 outline-none "
            />
        </div>
       
       

        <div className="text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Website: </label>
         
            <input
              name="website"
              value={website}
              placeholder="www.ex.com"
              onChange={onChange}
              className="border-gray bg-[#323338] rounded p-3 outline-none"
            />
       </div>
      </div>

      <button
        className="bg-red   rounded p-1 text-[16px]  w-[96%] mx-auto"
        onClick={onSubmit}
      >
        Save
      </button>
    </section>
  );
};

export default Artist;
