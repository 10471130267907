import React from "react";

const About_05 = ({ image }) => {
  return (
    <div
      className="h-full flex justify-center items-center w-full"
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className="max-w-[990px] w-full mx-auto text-white  flex flex-col gap-6 md:text-[14px] text-[12px] px-5">
        <h2 className=" md:text-5xl xs:text-2xl text-xl font-[600]">
          Created For Local Artists By A Local Artist
        </h2>
        <p className="text-center text-[14px] sm:text-xl lg:text-2xl xxl:text-3xl">
          Jakota is a singer based in Northern Nevada and was tired of the
          countless hours he was putting in after each show to capture new
          audience members information. He wanted a way to interact with the
          people enjoying his show by allowing them to request songs, get to
          know their names and let them send him messages while he is playing.
          More importantly, he wanted to be able to connect with these audience
          members after the gig was over. NextQuest was the solution that made a
          bridge between a musician and the listeners with a simple QR Code.
          Jakota originally developed this idea for his personal use and saw the
          amazing benefits it offered. Now he wants to share this with the music
          industry.
        </p>
      </div>
    </div>
  );
};

export default About_05;
