import React, { useState } from "react";
import Popup from "../layout/Popup";

const AddPlaylist = ({ handleAdd, handleClose }) => {
  const [formData, setFormData] = useState({
    id: Math.floor(Math.random() * 90 + 10),
    title: "",
    description: "",
  });

  const { title, description } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    handleAdd({
      status: "active",
      genre: "pop",
      title,
      description,
    });
    setFormData({ title: "", description: "" });
  };

  return (
    <Popup dark={true} popupstyles="p-0 m-0" setFunc={handleClose}>
      <form onSubmit={onSubmit} className="flex flex-col  gap-5  h-[100%] pb-5">
        <h1 className="text-4xl font-bold bg-black text-white w-full px-5 pt-12 sm:pb-4 pb-10 shadow-md border border-[#303947]">
          Add playlist
        </h1>

        {/* Covering Title and Message Feild  */}
        <div className="flex flex-col gap-4 flex-1  w-full p-6">
          {/* Title Feild  */}
          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="title">Title</label>
            <input
              placeholder="Title"
              name="title"
              id="title"
              value={title}
              onChange={onChange}
              className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
              required
            />
          </div>

          {/* Message Feild  */}
          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="message">Message</label>
            <textarea
              placeholder="Description"
              name="description"
              id="message"
              value={description}
              onChange={onChange}
              className="border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium resize-none w-full h-40  outline-none "
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          className="border-red  bg-red text-white border-[3px] px-6 py-2 font-bold rounded mx-6"
        >
          Add Playlist
        </button>
      </form>
    </Popup>
  );
};

export default AddPlaylist;
