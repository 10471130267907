import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";
import { IoMailSharp } from "react-icons/io5";
import domtoimage from "dom-to-image";

import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { PLACEHOLDER_IMAGE } from "../../constants";
// import { ImFacebook2 } from "react-icons/im";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaGlobeAfrica } from "react-icons/fa";
import { BounceLoader } from "react-spinners";
const FlyerFront = ({ enableState, theme, setEnableState, userImage }) => {
  const [image, setImage] = useState(null);
  // const [userImage, setUserImage] = useState("");
  const [hiddenClass, setHiddenClass] = useState("hidden");
  const [loading, setLoading] = useState();
  const [existingImage, setExistingImage] = useState(null);
  const hiddenRef = useRef(null);

  const {
    state: { user },
  } = authStore;

  const images = {
    blackTemplate: process.env.PUBLIC_URL + "/assets/icons/black_template.jpg",
    mail: process.env.PUBLIC_URL + "/assets/icons/mail.png",
    insta: process.env.PUBLIC_URL + "/assets/icons/insta.png",
    facebook: process.env.PUBLIC_URL + "/assets/icons/facebook.png",
    venmo: process.env.PUBLIC_URL + "/assets/icons/venmo.png",
    front: process.env.PUBLIC_URL + "/assets/images/front.png",
    front_white: process.env.PUBLIC_URL + "/assets/images/front_white.png",
  };

  const fetchImage = () => {
    // setHiddenClass("flex");
    const container = document.getElementById("frontflayer");

    console.log(container.classList);
    // domtoimage
    //   .toPng(container, {
    //     quality: 1,
    //     // bgcolor: "#ffffff",
    //     // width: 1200,
    //     // height: 1800,
    //   }) // Specify width and height here
    //   .then((dataUrl) => {
    //     setImage(dataUrl);
    //   })
    //   .catch((error) => {
    //     console.error("Error generating image:", error);
    //   });
    // });
    // setHiddenClass("hidden");
  };

  const download = () => {
    // const container = document.getElementById("posterflayer");
    setHiddenClass("block");

    const container = hiddenRef.current;
    domtoimage
      .toPng(container, {
        quality: 1,
        bgcolor: "#000000",
      })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "front_flyer.png";
        a.click();
        setHiddenClass("hidden");
      })
      .catch((error) => {
        setHiddenClass("hidden");
        console.error("Error generating image:", error);
      });
  };

  useEffect(() => {
    fetchImage();
  }, [userImage, theme]);

  let venmoName = user?.venmo.split(" ");
  let name = user?.bandName.split(" ");

  return (
    <>
      {hiddenClass == "hidden" ? (
        <section className="flex flex-col gap-3">
          <section
            className={`w-[320px] rounded-lg overflow-hidden bg-contain bg-center px-4 h-[600px] bg-no-repeat flex flex-col items-center justify-center gap-4 text-[12px] ${
              theme
                ? "text-white bg-[#000] border-gray border-2"
                : "text-[#000] bg-white border-black border-2"
            }`}
            id="front"
          >
            <section className="flex justify-center flex-col gap-2 w-[90%] items-center text-center">
              <img
                className="w-[150px] h-[150px] object-cover  rounded-full"
                src={userImage ? userImage : PLACEHOLDER_IMAGE}
                // src={user?.image}
                alt="profile"
              />
              <span className="font-semibold text-xl">
                {user?.bandName && (
                  <span className="">
                    &nbsp;<span>{0 < name.length && name[0]}</span>&nbsp;
                    <span>{1 < name.length && name[1]}</span>&nbsp;
                    <span>{2 < name.length && name[2]}</span>&nbsp;
                    <span>{3 < name.length && name[3]}</span>&nbsp;
                    <span>{4 < name.length && name[4]}</span>&nbsp;
                    <span>{5 < name.length && name[5]}</span>
                  </span>
                )}
              </span>
              <p
                className="text-justify line-clamp-6"
                style={{ textWrap: "pretty", wordBreak: "break-word" }}
              >
                {user?.bio}
              </p>
            </section>

            <section className="flex flex-col gap-2 text-justify">
              {user.email && (
                <span className="flex items-center justify-start gap-3">
                  <div className="w-8 h-8 bg-[#0abde3] flex items-center justify-center rounded-full">
                    <IoMailSharp
                      size={24}
                      color=""
                      className="w-5 h-5 rounded text-white"
                    />
                  </div>
                  <p>{user.email}</p>
                </span>
              )}
              {user.facebookHandle && (
                <span className="flex items-center justify-start gap-4">
                  <FaFacebookF
                    size={24}
                    color=""
                    className="bg-[#1877F2] pt-[2px] text-white w-7 h-7 rounded"
                  />
                  <p>{user.facebookHandle}</p>
                </span>
              )}
              {user.instaHandle && (
                <span className="flex items-center justify-start gap-4">
                  <FaInstagramSquare
                    size={24}
                    color=""
                    className="logo_color text-backgroundImage w-7 h-7 rounded text-white"
                  />
                  <p>{user.instaHandle}</p>
                </span>
              )}
              {user.website && (
                <span className="flex items-center justify-start gap-4">
                  <FaGlobeAfrica className="w-7 h-7" />
                  <p>{user.website}</p>
                </span>
              )}
            </section>
            <section>
              {user.venmo && (
                <span
                  className={`flex items-center justify-center text-center rounded border-2 border-maroon ${
                    theme ? "text-white bg-gray" : "text-black bg-white"
                  } mt-1 px-2 p-1 border-red`}
                >
                  &nbsp; <span className="font-bold">Venmo </span>&nbsp;
                  {user?.venmo && (
                    <span className="">
                      &nbsp;<span>{0 < venmoName.length && venmoName[0]}</span>
                      &nbsp;
                      <span>{1 < venmoName.length && venmoName[1]}</span>&nbsp;
                      <span>{2 < venmoName.length && venmoName[2]}</span>&nbsp;
                      <span>{3 < venmoName.length && venmoName[3]}</span>&nbsp;
                      <span>{4 < venmoName.length && venmoName[4]}</span>&nbsp;
                      <span>{5 < venmoName.length && venmoName[5]}</span>
                    </span>
                  )}
                  &nbsp;&nbsp;
                </span>
              )}
            </section>
          </section>
          <button
            className="bg-red p-2 rounded text-[16px] w-full"
            onClick={download}
          >
            DOWNLOAD
          </button>
        </section>
      ) : (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      )}

      <section className={`${hiddenClass}`}>
        <section className={`flex flex-col gap-3`}>
          <section
            className={`w-[1200px] rounded-lg   overflow-hidden bg-contain bg-center px-4 h-[1800px] bg-no-repeat flex flex-col items-center justify-center gap-12 text-[12px] ${
              theme
                ? "text-white bg-[#000] border-gray border-2"
                : "text-[#000] bg-white border-black border-2"
            } `}
            id="frontflayer"
            ref={hiddenRef}
          >
            <section className="flex justify-center flex-col gap-2 w-[90%] items-center text-center">
              <img
                className="w-[400px] h-[400px] object-cover rounded-full"
                src={userImage ? userImage : PLACEHOLDER_IMAGE}
                alt="profile"
              />
              <span className="font-semibold text-7xl mt-6">
                {user?.bandName && (
                  <span>
                    &nbsp;<span>{0 < name.length && name[0]}</span>&nbsp;
                    <span>{1 < name.length && name[1]}</span>&nbsp;
                    <span>{2 < name.length && name[2]}</span>&nbsp;
                    <span>{3 < name.length && name[3]}</span>&nbsp;
                    <span>{4 < name.length && name[4]}</span>&nbsp;
                    <span>{5 < name.length && name[5]}</span>
                  </span>
                )}
              </span>
              <p
                className="text-center line-clamp-6 text-4xl mt-6  leading-[70px]"
                style={{ textWrap: "pretty", wordBreak: "break-word" }}
              >
                {user?.bio}
              </p>
            </section>

            <section className="flex text-2xl flex-col gap-10 text-justify">
              {user.email && (
                <span className="flex items-center justify-start gap-3 ">
                  <div className="w-20 h-20 bg-[#0abde3] flex items-center justify-center rounded-full">
                    <IoMailSharp
                      size={24}
                      color=""
                      className="w-12 h-12 rounded text-white"
                    />
                  </div>
                  <p>{user.email}</p>
                </span>
              )}
              {user.facebookHandle && (
                <span className="flex items-center justify-start gap-4">
                  <FaFacebookF
                    size={40}
                    color=""
                    className="bg-[#1877F2] pt-[2px] text-white w-16 h-16 rounded"
                  />
                  <p>{user.facebookHandle}</p>
                </span>
              )}
              {user.instaHandle && (
                <span className="flex items-center justify-start gap-4">
                  <FaInstagramSquare
                    size={24}
                    color=""
                    className="logo_color text-backgroundImage w-16 h-16 rounded text-white"
                  />
                  <p>{user.instaHandle}</p>
                </span>
              )}
              {user.website && (
                <span className="flex items-center justify-start gap-4">
                  <FaGlobeAfrica className="w-16 h-16" />
                  <p>{user.website}</p>
                </span>
              )}
            </section>
            <section>
              {user.venmo && (
                <span
                  className={`flex items-center justify-center text-center self-start text-3xl  mt-6  rounded border-2 border-maroon ${
                    theme ? "text-white bg-gray" : "text-black bg-white"
                  } mt-1 px-2 p-3 border-red`}
                >
                  &nbsp; <span className="font-bold">Venmo </span>&nbsp;
                  {user?.venmo && (
                    <span>
                      &nbsp;<span>{0 < venmoName.length && venmoName[0]}</span>
                      &nbsp;
                      <span>{1 < venmoName.length && venmoName[1]}</span>&nbsp;
                      <span>{2 < venmoName.length && venmoName[2]}</span>&nbsp;
                      <span>{3 < venmoName.length && venmoName[3]}</span>&nbsp;
                      <span>{4 < venmoName.length && venmoName[4]}</span>&nbsp;
                      <span>{5 < venmoName.length && venmoName[5]}</span>
                    </span>
                  )}
                  &nbsp;&nbsp;
                </span>
              )}
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default observer(FlyerFront);
