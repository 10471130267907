import React from "react";
import { BASE_URL } from "../../constants";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

const Main = ({ userId, setShow }) => {
  return (
    <section className="max-w-[880px] w-[96%] mx-auto flex flex-col gap-8 items-center justify-center min-h-screen">
      <h2 className="sm:text-5xl text-3xl font-semibold">Templates</h2>
      <p className="text-center sm:text-[16px] text-[12px]">
        Craft personalized posters and flyers, complete with your unique QR code
        to engage your audience, or kickstart your project with our ready-made
        templates!
      </p>

      <h4 className="sm:text-2xl text-lg font-semibold">
        Your Personalized QR Code:
      </h4>

      {/* Qr Code */}

      <img
        src={
          BASE_URL +
          "/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          userId
        }
        alt="qr-code"
        className="h-32 w-32 box box-e"
      />

      <section className=" flex items-center gap-4">
        <Link
          to={
            BASE_URL +
            "/template/generateQR?url=https://nextquestup.com/audience-requests/" +
            userId
          }
          download="template.jpg"
          target="_blank"
        >
          <button className=" mx-auto bg-red py-2 xs:px-6  px-3 sm:w-[250px] text-[12px] sm:text-[14px]  rounded text-white">
            Download QR Code
          </button>
        </Link>
        <button
          className="text-center mx-auto bg-[#303947]  sm:w-[250px] py-2  xs:px-6 px-3  text-[12px] sm:text-[14px] rounded text-white"
          onClick={() => setShow("flayer")}
        >
          View Templates
        </button>
      </section>

      <Link
        className="sm:text-[16px] flex gap-3 items-center cursor-pointer text-[12px] "
        target="_blank"
        to={window.location.origin + "/audience-requests/" + userId}
      >
        <span>Click here to visit your audience page</span>
        <FaExternalLinkAlt color="#FB2E33" />
      </Link>
    </section>
  );
};

export default Main;
