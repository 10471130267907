import React from "react";
import PlaylistItem from "./PlaylistItem";
import authStore from "../../store/auth";
import { toast } from "react-toastify";

const LeftSide = ({
  searchText,
  setSearchText,
  playlists,
  setEdit,
  setPlaylist,
  setShow,
  setDeletion,
  playlist,
  editPlaylistStatus,
}) => {
  const {
    state: { user },
  } = authStore;
  return (
    <div className="bg-[#22252A]  border-[#303947] border-2 rounded h-[85vh] flex flex-col pb-2 lg:col-span-5  col-span-12 ">
      {/* Upper Box  --- Search Bar Area*/}
      <div className="flex justify-between items-center p-3 border-b-2 border-[#303947]  py-5">
        <h3 className="sm:text-2xl text-lg font-semibold text-white">
          Playlist
        </h3>

        <div className="bg-[#1E2126] flex xs:p-2 p-1 border-[#485261] border-2 rounded-lg  xxl:w-[250px] sm:w-[200px]  w-[150px]    overflow-hidden">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/search-icon.svg`}
            height={20}
            width={20}
            className="mr-1"
          />
          <input
            type="text"
            className="flex-1 outline-none bg-[#1E2126] text-white sm:text-lg text-xs"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <p className="text-[14px] p-2">
        Choose which playlist will be displayed on your audience page.
      </p>
      <div className="h-full p-3 overflow-y-auto container-inner">
        {playlists.length > 0 ? (
          <>
            {/* <React.Fragment key={i}> */}
            {searchText.length > 0 ? (
              <>
                <div className="grid grid-cols-12 gap-3 text-xs lg:text-[14px]">
                  <h5 class="text-start  p-2 text-white col-span-6 ">List</h5>
                  <h5 class="text-start p-2 text-white col-span-2">Actions</h5>
                  <h5 class="text-start p-2 text-white col-span-4">
                    Active/Inactive
                  </h5>
                </div>
                {playlists
                  .filter((x) =>
                    x.title.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((x, i) => (
                    <PlaylistItem
                      key={i}
                      showBorder={i > 0}
                      playlist={x}
                      isAllowed={user?.subscription_active}
                      setEdit={setEdit}
                      editPlaylistStatus={editPlaylistStatus}
                      setPlaylist={setPlaylist}
                      setShow={setShow}
                      isRed={playlist?.playlistId === x?.playlistId}
                      setDeletion={setDeletion}
                    />
                  ))}
              </>
            ) : (
              <>
                <div className="grid grid-cols-12 gap-3 overflow-hidden text-xs lg:text-[14px]">
                  <h5 class="text-start  p-2 text-white col-span-6">List</h5>
                  <h5 class="text-center p-2 text-white col-span-2">Actions</h5>
                  <h5 class="text-center p-2 text-white col-span-4">
                    Active/Inactive
                  </h5>
                </div>
                {playlists.map((x, i) => (
                  <>
                    <PlaylistItem
                      key={i}
                      showBorder={i > 0}
                      playlist={x}
                      setEdit={setEdit}
                      editPlaylistStatus={editPlaylistStatus}
                      setPlaylist={setPlaylist}
                      setShow={setShow}
                      isRed={playlist?.playlistId === x?.playlistId}
                      setDeletion={setDeletion}
                      isAllowed={user?.subscription_active}
                    />
                  </>
                ))}
              </>
            )}
            {/* </React.Fragment> */}
          </>
        ) : (
          <div className="h-full flex justify-center items-center">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/Playlist_left_side_01.svg"
              }
              className="h-[60%]"
              alt="No playlists"
            />
          </div>
        )}
      </div>
      {/* Playlist Rendering Area  */}

      {/* Add Playlist Button */}

      <button
        // disabled={user?.subscription_active ? false : true}
        className="w-[96%] mx-auto  bg-red p-2 rounded-md text-white mt-5 "
        onClick={() => {
          if (user?.subscription_active) {
            setShow("add");
          } else {
            toast.error("Please Activate Your Subscription!");
          }
        }}
      >
        Add Playlist
      </button>
    </div>
  );
};

export default LeftSide;
