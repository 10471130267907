import React from "react";

const ToggleButton = ({ handleClick, toggle }) => {
  return (
    <button
      onClick={handleClick}
      className={`${
        toggle ? "bg-red" : "bg-pink"
      } transition-all duration-150 ease-out hover:ease-in h-6 w-10 rounded-full p-1 flex ${
        !toggle ? "justify-start" : "justify-end"
      }`}
    >
      <div className="h-4 w-4 bg-white rounded-full" />
    </button>
  );
};

export default ToggleButton;
