import React, { useEffect, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import FlyerFront from "./FlyerFront";
import FlyerBack from "./FlyerBack";
import Poster from "./Poster";
import FlayersScreen from "./FlayersScreen";
import ToggleButton from "../ToggleButton";
import ThirdFlyer from "./ThirdFlyer";
import PrintAtHome from "./PrintAtHome";
const Flayer = ({ setShow, show, userImage }) => {
  const [viewScreen, setViewScreen] = useState("flyer");

  const [theme, setTheme] = useState(true);
  useEffect(() => {
    // setShow("flyer");
  }, []);

  return (
    <section className="lg:w-[90%] xl:w-[90%] w-[96%] mx-auto mt-12  flex flex-col xs:px-2 py-2 gap-2">
      <h2 className="text-4xl font-semibold flex items-center gap-2 cursor-pointer">
        <HiArrowNarrowLeft onClick={() => setShow("template")} />
        <span>Templates</span>
      </h2>

      <section className="  p-1 xs:p-3 flex flex-col gap-2  bg-[#22252A] border-[#303947] border-2 rounded">
        {/* Upper Header */}
        <section className="flex items-center w-full  text-sm sm:text-lg ">
          <h4
            className={`cursor-pointer text-center pb-2  flex-1 ${
              viewScreen === "flyer"
                ? "border-b-[5px] border-red"
                : "text-[#C2C2C2]"
            }`}
            onClick={() => setViewScreen("flyer")}
          >
            Flyer
          </h4>
          <h4
            className={`cursor-pointer text-center pb-2  flex-1 ${
              viewScreen === "poster"
                ? "border-b-[5px] border-red"
                : "text-[#C2C2C2]"
            }`}
            onClick={() => setViewScreen("poster")}
          >
            Poster
          </h4>
          <h4
            className={`cursor-pointer text-center pb-2  flex-1 ${
              viewScreen === "Print At Home"
                ? "border-b-[5px] border-red"
                : "text-[#C2C2C2]"
            }`}
            onClick={() => setViewScreen("Print At Home")}
          >
            Print At Home
          </h4>
        </section>

        {viewScreen !== "Print At Home" && (
          <section className="flex gap-2">
            <ToggleButton
              toggle={theme}
              handleClick={() => setTheme((theme) => !theme)}
            />

            <label>
              {theme ? "Change to Light Mode" : "Change to Dark Mode  "}
            </label>
          </section>
        )}

        {viewScreen === "flyer" && (
          <FlayersScreen theme={theme} userImage={userImage} />
        )}

        {viewScreen === "poster" && (
          <Poster theme={theme} userImage={userImage} />
        )}
        {viewScreen === "Print At Home" && <PrintAtHome theme={theme} />}
      </section>
    </section>
  );
};

export default Flayer;
