import React from "react";
import About_03_Card from "./About_03_Card";

const About_02 = ({ image }) => {
  let data = [
    {
      image: `${process.env.PUBLIC_URL}/assets/icons/user.png`,
      title: "Create a free Account",
      description:
        "As soon as you make an account, enjoy a 6 month subscription free of charge, no credit card necessary.",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/icons/music.png`,
      title: "Create Playlists",
      description:
        "Create new playlists and add songs to them. Choose which playlist(s) you will play at each performance.",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/icons/qr.png`,
      title: "Create Your Cards",
      description:
        "Create a request card with your customized QR code. This will allow your audience to scan the code and send you song requests through your personalized NextQuest profile.",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/icons/users.png`,
      title: "Start Interacting",
      description:
        "You are ready to go! Place your request cards in a convenient location for your audience to scan. Start the unique interaction NextQuest brings to your shows and use our analytics to grow your following!",
    },
  ];
  return (
    <div
      className="h-full flex justify-center items-center w-full"
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div
        className="  flex flex-col flex-wrap gap-6 items-center
      
      mx-auto text-white xl:w-[990px] md:w-[700px] sm:w-[600px] w-[92%] md:text-[16px] text-[14px]
      "
      >
        <h2 className="lg:text-4xl text-2xl font-[600]">How It Works</h2>
        <ul className="grid grid-cols-12 gap-4 items-center justify-center">
          {data.map((ele, index) => {
            return <About_03_Card index={index} item={ele} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default About_02;
