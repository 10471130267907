import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";

import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { BounceLoader } from "react-spinners";

const FlyerBack = ({ link, theme, enableState, setEnableState }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [hiddenClass, setHiddenClass] = useState("hidden");
  const [existingImage, setExistingImage] = useState(null);

  const hiddenRef = useRef(null);
  const {
    state: { user },
  } = authStore;

  const images = {
    back: process.env.PUBLIC_URL + "/assets/images/back.png",
    back_white: process.env.PUBLIC_URL + "/assets/images/back_white.png",
  };

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user.image && fetchUserImage();
    user &&
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );

    {
      enableState && download();
    }
    setEnableState(false);
  }, [user, enableState]);

  const fetchImage = () => {};

  const download = () => {
    // const container = document.getElementById("posterflayer");
    setHiddenClass("block");

    const container = hiddenRef.current;
    domtoimage
      .toPng(container, {
        quality: 1,
        bgcolor: "#000000",
      })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "back_flyer.png";
        a.click();
        setHiddenClass("hidden");
      })
      .catch((error) => {
        setHiddenClass("hidden");
        console.error("Error generating image:", error);
      });
  };

  useEffect(() => {
    fetchImage();
  }, [userImage, theme]);

  return (
    <>
      {hiddenClass == "hidden" ? (
        <section className="flex flex-col gap-3 ">
          <section
            className={`${
              theme
                ? "text-white bg-[#000] border-gray border-2"
                : "text-[#000] bg-white border-black border-2"
            } w-[320px]      bg-center bg-contain px-4 h-[600px]   bg-no-repeat flex  flex-col items-center gap-4 rounded text-[14px] justify-center `}
            // style={{
            id="back"
          >
            {/* <section className="flex flex-col gap-2">
        <p className="font-semibold text-[14px]">
          {user?.firstName}&nbsp;{user?.lastName}
        </p>
        <p className="text-center line-clamp-2">{user?.bio}</p>
      </section> */}
            <h6 className="font-semibold text-xl">Make&nbsp;A&nbsp;Request</h6>

            <div className="head flex justify-center w-full">
              <img
                className="w-[80%] h-[150px] object-contain"
                src={qrCode}
                alt="QR"
              />
            </div>
            <section className="w-[90%] flex flex-col gap-5 text-center">
              <p className="text-center text-lg">
                Scan the QR code with your mobile device to view my song list.
              </p>
              <p className="text-center text-lg">
                Click on a Song to send your <span className="text-red">N</span>
                ext<span className="text-red">Q</span>uest
              </p>
              {theme && (
                <section
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/logo-xl.png)`,
                  }}
                  className="w-[120px] h-16  bg-no-repeat bg-contain mx-auto"
                ></section>
              )}
            </section>
            <p>www.nextquestup.com</p>
          </section>
          <button
            className="bg-red p-2 rounded text-[16px] "
            onClick={download}
          >
            DOWNLOAD
          </button>
        </section>
      ) : (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      )}
      <section className={`flex flex-col gap-3 ${hiddenClass}`}>
        <section
          className={`${
            theme
              ? "text-white bg-[#000] border-gray border-2"
              : "text-[#000] bg-white border-black border-2"
          } w-[1200px]      bg-center bg-contain px-4 h-[1800px]   bg-no-repeat flex  flex-col items-center gap-10 rounded text-[14px] justify-center `}
          // style={{
          id="back"
          ref={hiddenRef}
        >
          {/* <section className="flex flex-col gap-2">
        <p className="font-semibold text-[14px]">
          {user?.firstName}&nbsp;{user?.lastName}
        </p>
        <p className="text-center line-clamp-2">{user?.bio}</p>
      </section> */}
          <h6 className="font-semibold text-6xl">Make&nbsp;A&nbsp;Request</h6>
          <br />
          <div className="head flex justify-center w-full ">
            <img
              className="w-[80%] h-[350px] object-contain"
              src={qrCode}
              alt="QR"
            />
          </div>
          <br />
          <section className="w-[90%] flex flex-col gap-5 text-center">
            <p className="text-center text-4xl ">
              Scan the QR code with your mobile device to view my song list.
            </p>
            <br />
            <p className="text-center  text-4xl">
              Click on a Song to send your <span className="text-red">N</span>
              ext<span className="text-red">Q</span>uest
            </p>
            <br />
            {theme && (
              // <section
              //   style={{
              //     backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/logo-xl.png)`,
              //   }}
              //   className="w-[400px] h-40  bg-no-repeat bg-fill mx-auto"
              // ></section>
              // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/logo-xl.png)`,
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/logo-xl.png`}
                className="w-[300px] mx-auto"
              />
            )}
          </section>
          <p className=" text-4xl">www.nextquestup.com</p>
        </section>
        <button className="bg-red p-2 rounded text-[16px] " onClick={download}>
          DOWNLOAD
        </button>
      </section>
    </>
  );
};

export default observer(FlyerBack);
