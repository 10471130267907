import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";

import get_query from "../actions/get_query";
import delete_query from "../actions/delete_query";
import put_query from "../actions/put_query";
import post_query from "../actions/post_query";
import { BASE_URL } from "../constants";

class RequestStore {
  state = {
    requests: [],
    request: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  getRequests = async (id) => {
    const res = await get_query("/request/allRequestsForArtist/" + id);
    // https://nextquestup.com/api/request/allRequestsForArtist/:userId
    if (res.code === 200) {
      this.state.requests = res.data.reverse();
    } else {
      toast.error("Error while fetching requests");
    }
  };
  getAllRequests = async (id) => {
    const res = await get_query("/request/allRequests/" + id);
    if (res.code === 200) {
      this.state.requests = res.data.reverse();

      console.log(res);
    } else {
      toast.error("Error while fetching requests");
    }
  };

  setRequest = (data) => {
    this.state.song = data;
  };

  addRequest = async (data) => {
    const res = await post_query("/request/addNew", data);

    if (res.code === 200) {
      // this.state.songs.push(res.data);
      toast.success("Request added successfully");
    } else {
      toast.error("Error while creating request");
    }
  };

  editRequest = async (data, id) => {
    const res = await put_query("/song/edit/" + id, data);

    if (res.code === 200) {
      this.state.songs = this.state.songs.map((x) =>
        x.songId === id ? { ...x, ...data } : x
      );
      this.state.song = { ...this.state.song, ...data };
      toast.success("Request updated successfully");
    } else {
      toast.error("Error while editing song");
    }
  };

  removeRequests = async (ids, id) => {
    // const data = ids.map((ele) => {
    //   return ele.requestId;
    // });
    try {
      const res = await fetch(BASE_URL + "/request/rejectRequests", {
        method: "PUT",
        body: JSON.stringify(ids),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("x-auth-token"),
        },
      });

      if (res?.status === 200) {
        this.getRequests(id);
        toast.success("Request Removed");
      } else {
        toast.error("Error while removing request");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error while removing request");
    }
  };

  acceptRequest = async (id) => {
    const res = await put_query("/request/accept/" + id);

    if (res.code === 200) {
      this.state.requests = this.state.requests.map((x, i) =>
        x.requestId === id ? { ...x, status: "completed" } : x
      );
      toast.success("Request accepted");
    } else {
      toast.error("Error while accepting request");
    }
  };

  rejectRequest = async (id) => {
    const res = await put_query("/request/reject/" + id);

    if (res.code === 200) {
      this.state.requests = this.state.requests.map((x, i) =>
        x.requestId === id ? { ...x, status: "skipped" } : x
      );
      toast.success("Request rejected");
    } else {
      toast.error("Error while rejecting request");
    }
  };

  deleteRequest = async (id) => {
    const res = await delete_query("/song/delete/" + id);
    if (res.code === 200) {
      this.state.songs = this.state.songs.filter((x) => x.songId !== id);
      toast.success("Request deleted successfully");
    } else {
      toast.error("Error while deleting song");
    }
  };
}

const requestStore = new RequestStore();
export default requestStore;
