import React from "react";
import Papa from "papaparse";

const exportToCSV = (data, filename) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename + ".csv");
  a.click();
};

const ExportCSVButton = ({ data, filename, title, extraStyle = "" }) => {
  const handleExport = () => {
    exportToCSV(data, filename);
  };

  return (
    <button
      className={`px-4 py-2 mb-3 sm:text-lg text-xs rounded border-2 border-red font-bold ${extraStyle}`}
      onClick={handleExport}
    >
      {title}
    </button>
  );
};

export default ExportCSVButton;
