import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import CreateRequest from "../component/audience/CreateRequest";

import userStore from "../store/user";
import songStore from "../store/song";
import { FaArrowLeft } from "react-icons/fa";
import { observer } from "mobx-react";
import requestStore from "../store/request";
import { FaChevronDown } from "react-icons/fa";
import PaymentMethod from "../component/audience/PaymentMethod";
import loadingStore from "../store/loading";
import { FaGlobeAfrica } from "react-icons/fa";
const AudienceRequests = () => {
  const { id } = useParams();
  const { getArtistById } = userStore;
  const {
    state: { songs },
    getSongs,
  } = songStore;
  const { addRequest } = requestStore;

  const [showForm, setShowForm] = useState(null);
  const [artist, setArtist] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [showMethods, setShowMethods] = useState(false);

  const fetchArtist = async () => {
    getArtistById(id).then((res) => setArtist(res));
  };

  const fetchSongs = async () => {
    getSongs(id);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("Song");
  useEffect(() => {
    fetchArtist();
    fetchSongs();
  }, [id]);

  useEffect(() => {
    if (searchText.length > 0) {
      let arr;

      if (filterValue === "Song") {
        arr = songs.filter((x) =>
          x.title?.toLowerCase().startsWith(searchText.toLowerCase())
        );
      } else if (filterValue === "Artist") {
        arr = songs.filter((x) =>
          x.artist?.toLowerCase().startsWith(searchText.toLowerCase())
        );
      } else {
        arr = songs.filter((x) =>
          x.title?.toLowerCase().startsWith(searchText.toLowerCase())
        );
      }

      // alert(JSON.stringify(arr));
      setFilteredSongs(arr);
    } else {
      setFilteredSongs(
        filterValue === "Song"
          ? songs.sort((a, b) => a.title.localeCompare(b.title))
          : songs.sort((a, b) => a.artist.localeCompare(b.artist))
      );
    }
  }, [searchText, songs, filterValue]);

  const onRequestSubmit = async (data) => {
    addRequest(data);
    setShowForm(null);
  };

  // alert(JSON.stringify(filteredSongs[0]));
  const navigate = useNavigate();

  
  return (
    <section className="mt-20 mb-5 py-b px-4 ">
      <Link className="flex items-center gap-2 text-2xl mb-4 ml-4" to={"/"}>
        {/* <FaArrowLeft
          className="text-2xl  cursor-pointer"
          onClick={() => navigate("/search")}
        />
        <span className="text-3xl">Search</span> */}

        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/logo-xl.png`}
          alt="Image is Missing"
          className="w-[120px] object-contain"
        />
      </Link>
      <section className="grid grid-cols-12 items-start md:gap-2 gap-5 min-h-screen ">
        {artist && (
          <section
            className="overflow-hidden lg:col-span-4 sm:col-span-8 md:col-span-5 col-span-12 
        py-5 px-2 text-center border-[#303947] bg-[#22252A] border-2 rounded flex justify-center items-center flex-col gap-5 "
          >
            {artist.image && (
              <img
                alt="audience"
                className="xs:h-[200px] xs:w-[200px] h-[120px] w-[120px] rounded-full object-cover border-2 border-grey"
                src={artist.image}
                style={{ boxShadow: "0px 4px 35px rgba(251, 46, 51, 0.5)" }}
              />
            )}
            <p className="text-2xl font-bold">
              {artist.firstName} {artist.lastName}
            </p>
            <section className="flex justify-center gap-3">
              {artist.facebook && (
                <div className="border-red p-1 border rounded">
                  <a
                    href={artist.facebook}
                    target="_blank"
                  >
                    <FaFacebookF />
                  </a>
                </div>
              )}
              {artist.instagram && (
                <div className="border-red p-1 border rounded">
                  <a
                    href={artist.instagram}
                    target="_blank"
                  >
                    <BsInstagram />
                  </a>
                </div>
              )}
               {artist.website && (
                 <div className="border-red p-1 border rounded">
                 <a
                   href={"https://"+artist.website}
                   target="_blank"
                 >
                   <FaGlobeAfrica />
                 </a>
               </div>
          
          )}
            </section>
           {artist.website ?  <a
                   href={"https://"+artist.website}
                   target="_blank"         
                   className="underline"
                 >
                  
                   { artist.website}
                 </a>:null} 
          
           {/* <section className="underline">{artist.website }</section> */}

            <section
              className="w-[80%] mx-auto  
            border-[#485261] bg-[#22252A] gap-1 p-2  flex flex-col items-center border rounded mt-5
            overflow-x-auto scrollbar"
            >
              <h6 className="text-[16px] font-semibold">Bio: </h6>

              <span className="p-2 text-justify ">
                {artist?.bio && (
                  <span
                    className="text-justify  text-[14px] "
                    // style={{ textWrap: "pretty" }}
                    style={{ wordBreak: "break-word" }}
                  >
                    {artist?.bio}
                  </span>
                )}
              </span>
            </section>
            <button
              onClick={() => setShowMethods(true)}
              className="px-4 py-2 w-[80%]  rounded mx-auto bg-red font-bold"
              style={{ wordBreak: "break-word" }}
            >
              Send Tip
            </button>
          </section>
        )}

        <section className="lg:col-span-8 col-span-12  border-2 border-[#303947]  bg-[#22252A] rounded-t min-h-screen">
          <section className="flex justify-between p-2 items-center sm:text-[16px] text-[12px] rounded drop-shadow-md drop-shadow-b border-b border-[#303947]">
            <h5 className="font-semibold">Click On A Song To Send A Request</h5>
            <div
              className="bg-[#1E2126] flex items-center p-2 border-[#485261] border-2 rounded 
            
            "
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/search-icon.svg`}
                height={20}
                width={20}
                className="mr-1"
                alt="Search Icon"
              />
              <input
                type="text"
                className="outline-none bg-[#1E2126] text-white flex-1"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </section>

          <section className="flex-1 overflow-y-auto mt-3 flex flex-col gap-3 h-[100vh] py-5 px-3">
            <div className=" flex items-center   relative">
              <p
                className="p-2 border-[#303947] border w-[120px] cursor-pointer rounded text-center flex items-end gap-2
                justify-between
                "
                onClick={() => setIsOpen((open) => !open)}
              >
                <span>{!filterValue ? "Song : " : filterValue}</span>{" "}
                <FaChevronDown />
              </p>
              {isOpen && (
                <div
                  className="absolute left-[70px] top-9 bg-white text-black
                flex flex-col  text-sm  rounded z-30 w-[100px]
                "
                >
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      setFilterValue("Song");
                      setIsOpen(false);
                    }}
                  >
                    Songs
                  </span>
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      setFilterValue("Artist");
                      setIsOpen(false);
                    }}
                  >
                    Artist
                  </span>
                </div>
              )}
            </div>
            {!loadingStore.isLoading &&
              filteredSongs &&
              filteredSongs.map((x, i) => (
                <button
                  key={i}
                  className="border-2 w-full py-3 rounded border-[#303947] drop-shadow-sm text-start px-4"
                  onClick={() =>
                    setShowForm({
                      songId: x.songId,
                      date: new Date(),
                      status: "active",
                      artistUserId: id,
                      artist: x.artist,
                      song: x.title,
                    })
                  }
                >
                  <p className="sm:text-lg text-sm font-bold">{x.title}</p>
                  <p className=" sm:text-sm text-xs">{x.artist}</p>
                </button>
              ))}
          </section>
        </section>
      </section>

      {showForm && (
        <CreateRequest
          data={showForm}
          onSubmit={onRequestSubmit}
          handleClose={() => setShowForm(null)}
        />
      )}
        {/*   */}
      {showMethods && (
        <PaymentMethod
          pdf={artist?.pdf}
          venmoLink={artist.venmoLink}
          cashAppLink={artist.cashAppLink}
          zelleLink={artist.zelleLink}
          handleClose={() => setShowMethods(false)}
        />
      )}
    </section>
  );
};

export default observer(AudienceRequests);
