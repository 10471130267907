import React, { useEffect, useState } from "react";
import CenterPopup from "../component/layout/CenterPopup";

import CampaignForm from "../component/campaign/CampaignForm";
import EmailForm from "../component/campaign/EmailForm";

import campaignStore from "../store/campaign";
import authStore from "../store/auth";
import { observer } from "mobx-react";

import Papa from "papaparse";
import CampaignLeftSide from "../component/campaign/CampaignLeftSide";
import CampaignRightSide from "../component/campaign/CampaignRightSide";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Campaign = () => {
  const navigate = useNavigate();
  const {
    state: { campaigns, campaign, campaign_emails },
    getCampaigns,
    addCampaign,
    editCampaign,
    setCampaign,
    deleteCampaign,
    getEmails,
    addCampaignEmail,
    removeCampaignEmail,
  } = campaignStore;

  const {
    state: { user },
  } = authStore;

  useEffect(() => {
    if (user && !user?.subscription_active) {
      toast.error("Please activate your subscription!");
      navigate("/settings");
    }
  }, [user]);
  const [showForm, setShowForm] = useState(false);
  const [deletion, setDeletion] = useState(false);
  const [emailDeletion, setEmailDeletion] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [emails, setEmails] = useState([]);
  const [editData, setEditData] = useState(null);
  const [emailsDateFilter, setEmailsDateFilter] = useState(null);

  useEffect(() => {
    getCampaigns();
    return () => {
      setEmails([]);
    };
  }, []);

  useEffect(() => {
    getEmails(emailsDateFilter).then(async (res) => {
      await setEmails(res);
    });
  }, [emailsDateFilter, showEmailForm]);

  const handleAddCampaign = (name, description) => {
    addCampaign({ name: name, description: description, userId: user.userId });
    setShowForm(false);
  };

  const handleEditCampaign = (name, description) => {
    editCampaign(
      {
        name: name,
        description: description,
        campaignId: editData.campaignId,
        userId: user.userId,
      },
      editData.campaignId
    );
    setEditData(null);
  };

  const handleToggleEmail = (data) => {
    const selected = campaign_emails.find((x) => x.email === data.email);
    if (selected) {
      removeCampaignEmail(selected.campaignParticipantsId, selected.email);
    } else {
      addCampaignEmail(data);
    }
  };

  const exportToCSV = (data, filename) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename + ".csv");
    a.click();
  };

  return (
    <>
      <div className="mt-12 flex-1 flex  flex-col lg:w-[90%] w-[92%]  mx-auto px-4">
        <h2 className="sm:text-5xl text-3xl font-semibold">Campaign</h2>

        {/* Covers the Entire Campaigns Section with Add Campaign and Add Song Section */}
        <div className=" lg:gap-1 gap-5 mt-3  grid grid-cols-12  pb-10">
          {/* Left Side Component -- Add Campaign */}
          <CampaignLeftSide
            campaigns={campaigns}
            setCampaign={setCampaign}
            setEditData={setEditData}
            deleteCampaign={deleteCampaign}
            setShowForm={setShowForm}
            searchText={searchText}
            campaign={campaign}
            setSearchText={setSearchText}
            setDeletion={setDeletion}
          />

          {/* Right Side Component -- Add Songs  */}

          <CampaignRightSide
            campaign={campaign}
            campaigns={campaigns}
            emailsDateFilter={emailsDateFilter}
            campaign_emails={campaign_emails}
            exportToCSV={exportToCSV}
            setShowEmailForm={setShowEmailForm}
            setEmailDeletion={setEmailDeletion}
          />
        </div>
      </div>

      {showForm && (
        <CampaignForm
          handleAddCampaign={handleAddCampaign}
          handleEditCampaign={handleEditCampaign}
          handleClose={() => setShowForm(false)}
          isEdit={false}
        />
      )}
      {emailDeletion && (
        <CenterPopup dark={true}>
          <div className="flex flex-col justify-center items-center gap-5">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/delete-circle-icon.svg`}
              height={36}
              width={36}
              className=" border-[#5E6A7B] cursor-pointer "
            />
            <p>Are you sure you want to delete this Email?</p>

            <div className="flex gap-3 items-center">
              <button
                className="px-6 py-2 rounded bg-[#636363]"
                onClick={() => setEmailDeletion(null)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 rounded bg-red"
                onClick={() => {
                  handleToggleEmail({ email: emailDeletion });
                  setEmailDeletion(null);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </CenterPopup>
      )}
      {showForm && (
        <CampaignForm
          handleAddCampaign={handleAddCampaign}
          handleEditCampaign={handleEditCampaign}
          handleClose={() => setShowForm(false)}
          isEdit={false}
        />
      )}

      {showEmailForm && (
        <EmailForm
          handleClose={() => setShowEmailForm(false)}
          emails={emails}
          handleToggle={handleToggleEmail}
          campaign={campaign}
          selected={campaign_emails}
          emailsDateFilter={emailsDateFilter}
          setEmailsDateFilter={setEmailsDateFilter}
        />
      )}

      {editData && (
        <CampaignForm
          handleAddCampaign={handleAddCampaign}
          handleEditCampaign={handleEditCampaign}
          handleClose={() => setEditData(null)}
          isEdit={true}
          editData={editData}
        />
      )}

      {deletion && (
        <CenterPopup dark={true}>
          <div className="flex flex-col justify-center items-center gap-5">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/delete-circle-icon.svg`}
              height={36}
              width={36}
              className=" border-[#5E6A7B] cursor-pointer "
            />
            <p>Are you sure you want to delete this Campaign?</p>

            <div className="flex gap-3 items-center">
              <button
                className="px-6 py-2 rounded bg-[#636363]"
                onClick={() => setDeletion(null)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 rounded bg-red"
                onClick={() => {
                  deleteCampaign(deletion.campaignId);
                  setDeletion(null);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </CenterPopup>
      )}
    </>
  );
};

export default observer(Campaign);
