import React, { useEffect, useState } from "react";
import Popup from "../layout/Popup";
import CenterPopup from "../layout/CenterPopup";
import { RxCross1 } from "react-icons/rx";
import { Document, Page } from "react-pdf";
import { toDataURL } from "../../utils/toDataUrl";

const PaymentMethod = ({ venmoLink, cashAppLink, handleClose, pdf }) => {
  const images = [
    process.env.PUBLIC_URL + "/assets/images/zelle.png",
    process.env.PUBLIC_URL + "/assets/images/venmo.png",
    process.env.PUBLIC_URL + "/assets/images/cashapp.png",
  ];

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [zellePDF, setZellePDF] = useState();

  const fetchUserImage = () => {
    toDataURL(pdf + "?x=1")
      .then((res) => {
        setZellePDF(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    pdf && fetchUserImage();
  }, []);
  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
  }
  const [pdfOpen, setOpenDPF] = useState(false);
  return (
    <Popup dark={true} popupstyles="p-0 m-0" setFunc={handleClose}>
      <div className="relative flex flex-col  gap-5 justify-center items-center bg-[#272727] text-white pb-10 ">
        {/* <button
          onClick={handleClose}
          className="absolute sm:text-2xl text-xl sm:top-0 sm:right-0 top-[-15px] right-[-15px] text-white"
        >
          <RxCross1 />
        </button>
        <h1 className="sm:text-3xl text-xl font-bold sm:mb-5">
          Select Tipping Method
        </h1> */}
        <h1 className="text-2xl sm:text-4xl font-bold bg-black text-white w-full xs:px-5 pl-2 pt-12 pb-4 shadow-md border border-[#303947]">
          Select Tiping Method
        </h1>

        <p className="flex items-center gap-5 sm:text-xl sm:my-5 my-3">
          <a
            target="_blank"
            href={cashAppLink}
            onClick={handleClose}
            className="bg-black/50 p-4 rounded flex flex-col items-center"
          >
            <img
              className="bg-black/50 sm:p-3 p-1 rounded sm:h-16 h-8"
              src={process.env.PUBLIC_URL + "/assets/images/cash.svg"}
              alt="cash"
            />
          </a>
          Find Tip Jar Upfront!
        </p>

        {(cashAppLink || pdf || cashAppLink) && (
          <section className="flex w-[60%] mx-auto items-center gap-2">
            <span className="border-b-2  flex-1 border-gray"></span>
            <p>other Options</p>
            <span className="border-b-2  flex-1 border-gray"></span>
          </section>
        )}

        <div className="flex items-center gap-5 mt-5">
          {venmoLink && (
            <a
              target="_blank"
              href={venmoLink}
              onClick={handleClose}
              className="bg-black/50 sm:px-6 px-2 sm:py-5 py-2 rounded flex flex-col items-center"
            >
              <img
                className="bg-black/50 sm:p-3 p-1 rounded sm:h-16 h-8"
                src={process.env.PUBLIC_URL + "/assets/images/venmo.png"}
                alt="cash"
              />
            </a>
          )}
          {pdf && (
            <section
              className="bg-black/50 p-4 rounded flex flex-col items-center cursor-pointer"
              onClick={() => {
                setOpenDPF(true);
              }}
            >
              {/* <a
                target="_blank"
                href={pdf}
                onClick={handleClose}
                className="npobg-black/50 p-4 rounded flex flex-col items-center"
              > */}
              <img
                className="bg-black/50 sm:p-3 p-1 rounded sm:h-16 h-8"
                src={process.env.PUBLIC_URL + "/assets/images/zelle.png"}
                alt="cash"
              />

              {pdfOpen && (
                <CenterPopup dark={true} popupstyles="h-[500px] w-[100vw]">
                  <p
                    className="p-[15px] rounded-full cursor-pointer right-2 top-2 bg-gray  w-10 h-10 flex items-center justify-center mb-3"
                    onClick={() => handleClose()}
                  >
                    <RxCross1 />
                  </p>

                  {/* <section> */}
                  <iframe
                    id="pdf-viewer"
                    src={zellePDF}
                    target={"_blank"}
                    width={500}
                    height={400}
                    // height={400}
                    seamless
                  />
                  {/* <object
                    data={zellePDF}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <p>
                      Alternative text - include a link{" "}
                      <a href="http://africau.edu/images/default/sample.pdf">
                        to the PDF!
                      </a>
                    </p>
                  </object> */}

                  {/* </section> */}
                </CenterPopup>
              )}
              {/* </a> */}
            </section>
          )}
          {cashAppLink && (
            <a
              target="_blank"
              href={cashAppLink}
              onClick={handleClose}
              className="bg-black/50 p-4 rounded flex flex-col items-center"
            >
              <img
                className="bg-black/50 sm:p-3 p-1 rounded sm:h-16 h-8"
                src={process.env.PUBLIC_URL + "/assets/images/cashapp.png"}
                alt="cash"
              />
            </a>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default PaymentMethod;
