import React from "react";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <div className="flex justify-center items-center flex-col gap-10 mt-10">
      <p className="text-8xl">👎</p>
      <p className="text-3xl">Payment has failed</p>
      <button className="border-2 border-red rounded-full text-white px-4 py-2">
        <Link to="/playlist">Go to playlists</Link>
      </button>
    </div>
  );
};

export default PaymentFailed;
