import React, { useEffect, useState, useRef } from "react";

import { observer } from "mobx-react";
import authStore from "../store/auth";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Main from "../component/templates/Main";
import Flayer from "../component/templates/Flayer";
import { toDataURL } from "../utils/toDataUrl";

const Template = () => {
  const {
    state: { user },
  } = authStore;

  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const [show, setShow] = useState("template");

  const fetchUserImage = async () => {
    await toDataURL(user.image + "?x=1")
      .then((res) => {
        setImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user.image && fetchUserImage();
  }, [image, user]);

  return (
    <section>
      {show === "template" && (
        <Main userId={user?.userId} setShow={setShow} show={show} />
      )}
      {show === "flayer" && (
        <Flayer setShow={setShow} show={show} userImage={image} />
      )}
    </section>
  );
};

export default observer(Template);
