import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import CenterPopup from "../component/layout/CenterPopup";
import { RxCross1 } from "react-icons/rx";
const videoData = [
  {
    id: "x3PUf0BLM3I",
    link: "https://www.youtube.com/watch?v=x3PUf0BLM3I",
    title: "NextQuest, The Bridge Between the Artist and Audience",
    img: process.env.PUBLIC_URL + "/assets/images/main_video_image.PNG",
    src: "https://www.youtube.com/embed/x3PUf0BLM3I?si=8mtEpDfff8YEiWqE",
  },
  {
    id: "hvXotgxBOvk",
    img: process.env.PUBLIC_URL + "/assets/images/video_first.jpeg",
    title: "Create Your Next Quest Account",
    src: "https://www.youtube.com/embed/jhdi1nmtXbQ?si=wUCw1K7I15cNH0aK",
    link: "https://www.youtube.com/watch?v=E2mlGJ_Cu68",
  },
  {
    id: "MmCTJNr10xc",
    title: "Creating Playlist and Uploading Songs",
    img: process.env.PUBLIC_URL + "/assets/images/video_second.jpeg",
    src: "https://www.youtube.com/embed/SSRGk8s1hug?si=ipzr-zYMKu7mjLSO",
    link: "https://www.youtube.com/watch?v=YL9DmlC41bg&t=1s",
  },
  {
    id: "VXQuHSi0FqE",
    title: "View Your Audience Data & Create Email Compaigns",
    img: process.env.PUBLIC_URL + "/assets/images/video_third.jpeg",
    src: "https://www.youtube.com/embed/YL9DmlC41bg?si=0Ld5M9_Luh1uoTuN",
    link: "https://www.youtube.com/watch?v=SSRGk8s1hug",
  },
  {
    id: "VXQuHSi0FqE",
    title: "Let's Print Your Cards",
    img: process.env.PUBLIC_URL + "/assets/images/video_fourth.jpeg",
    src: "https://www.youtube.com/embed/E2mlGJ_Cu68?si=bTU3U4tOsaWT9Gky",
    link: "https://www.youtube.com/watch?v=jhdi1nmtXbQ&t=3s",
  },
];
const Tutorials = () => {
  const [openVideo, setOpenVideo] = useState(null);
  return (
    <div className="min-h-[100vh] mx-auto">
      <div className="max-w-[1310px] w-[92%] mx-auto flex flex-col  lg:items-stretch mx-auto items-center justify-center  mt-10 rounded-tl-lg rounded-bl-lg ">
        <h2 className="lg:text-5xl text-2xl font-[600] text-center  mb-8">
          Tutorials
        </h2>

        <div className="grid md:grid-cols-2 grid-cols-1 xl:grid-cols-3 gap-6 justify-center items-center">
          {videoData.map((ele, index) => {
            return (
              <div
                key={index}
                className="flex flex-col gap-3 bg-black/90 pb-5 rounded-xl hover:translate-y-2 duration-500 transition-all"
              >
                <div className="min-h-[250px] bg-white relative  rounded-xl">
                  <img
                    src={ele.img}
                    alt={ele.title}
                    title={ele.title}
                    className="h-full w-full object-stretch min-h-[300px]  rounded-xl object-center"
                  />

                  <div
                    className="absolute bottom-5 left-5 p-3 rounded-full bg-white/50 cursor-pointer"
                    onClick={() => setOpenVideo(ele.src)}
                  >
                    <FaPlay size={28} className="ml-1" />
                  </div>
                </div>
                <a href={ele?.link} target="_blank">
                  <p className=" cursor-pointer text-xl truncate p-3">
                    {ele.title}
                  </p>
                </a>
              </div>
            );
          })}
        </div>

        {openVideo ? (
          <CenterPopup
            dark={true}
            popupstyles="relative !max-w-[55rem] !w-[95%] max-w-[1000px]  z !h-[560px]"
          >
            <p
              className="p-[15px] absolute  rounded-full cursor-pointer  -top-3 -right-3 bg-gray  w-10 h-10 flex items-center justify-center mb-3"
              onClick={() => setOpenVideo(null)}
            >
              <RxCross1 />
            </p>
            <div className="flex justify-center w-full">
              <iframe
                width="560"
                height="500"
                className="w-full"
                src={openVideo}
                title="YouTube video player"
                style={{ borderRadius: 12 }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </CenterPopup>
        ) : null}

        {/* {openVideo ? (

            
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jhdi1nmtXbQ?si=wUCw1K7I15cNH0aK"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        ) : null} */}
      </div>
    </div>
  );
};

export default Tutorials;
