import React from "react";

const UpdatePassword = ({ formData, resetPassword, setPasswordObj }) => {
  return (
    <form
      className="flex flex-col  gap-5  h-[100%] sm:pb-5 pb-10"
      onSubmit={resetPassword}
    >
      <h1 className="text-4xl font-bold bg-black text-white w-full px-5 pt-12 pb-4 shadow-md border border-[#303947]">
        Change Password
      </h1>
      <div className="flex flex-col gap-4 flex-1  w-full p-4">
        {/* Title Feild  */}
        <div className="flex flex-col text-white font-semibold w-full gap-1">
          <label htmlFor="title">Current Password:</label>
          <input
            placeholder="input current password"
            name="password"
            id="title"
            value={formData?.password}
            onChange={(e) =>
              setPasswordObj({ ...formData, password: e.target.value })
            }
            className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
            required
          />
        </div>
        <div className="flex flex-col text-white font-semibold w-full gap-1">
          <label htmlFor="title">New Password:</label>
          <input
            placeholder="input new password"
            name="newPassword"
            id="title"
            value={formData?.newPassword}
            onChange={(e) =>
              setPasswordObj({ ...formData, newPassword: e.target.value })
            }
            className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
            required
          />
        </div>
        <div className="flex flex-col text-white font-semibold w-full gap-1">
          <label htmlFor="title">Confirm Password:</label>
          <input
            placeholder="input confirm password"
            name="confirmPassword"
            id="title"
            value={formData?.confirmPassword}
            onChange={(e) =>
              setPasswordObj({ ...formData, confirmPassword: e.target.value })
            }
            className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
            required
          />
        </div>
      </div>

      <button
        type="submit"
        className="border-red  bg-red text-white border-[3px] px-6 py-2 font-bold rounded mx-6"
      >
        Change Password
      </button>
    </form>
  );
};

export default UpdatePassword;
