import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // Import cropperjs styles
import "./app.css"; // Add custom styles for your app

const App = ({ handleSubmit = () => {} }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isHighResolution, setIsHighResolution] = useState(true); // State to track resolution validity
  const cropperRef = useRef(null);

  // Function to convert base64 to a File
  function base64ToFile(base64, filename) {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1]; // Get the mime type
    const bstr = atob(arr[1]); // Decode base64 string
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the file is an image
    if (file && file.type.match(/^image\//)) {
      // Check for file size (e.g., minimum 1MB = 1048576 bytes)
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            // Check the image dimensions (width and height)
            const imageWidth = img.width;
            const imageHeight = img.height;

            // Check if the image meets the minimum width and height requirements
            // if (imageWidth >= 720 && imageHeight >= 1080) {
            setImage(reader.result); // Set the image for cropping
            setIsHighResolution(true); // Set as high resolution
          };
          // else {
          // setImage(null);
          // setIsHighResolution(false);
          // alert(
          //   "Please upload an image with at least 720px width and 1080px height."
          // );
          // }
          // };

          img.src = reader.result; // Set the image source
        };

        reader.readAsDataURL(file);
      } else {
        setImage(null);
        setIsHighResolution(false);
        // alert("Please upload an image with a minimum size of 1MB.");
      }
    } else {
      alert("Invalid file type! Please select an image file.");
    }
  };

  // Handle crop button click
  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const croppedDataUrl = croppedCanvas.toDataURL("image/png");
      setCroppedImage(croppedDataUrl);
      let url = base64ToFile(croppedDataUrl, "cropped_image.png");

      console.log(url, "urlllllllllllllllllllllll:::");
      handleSubmit(url);
    }
  };

  // Handle restore button click
  const handleRestore = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.reset();
      setCroppedImage(null);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        id="fileInput"
      />
      {image && (
        <div>
          <Cropper
            src={image}
            style={{ height: 400, width: "100%" }}
            // aspectRatio={16 / 9}
            guides={false}
            ref={cropperRef}
            cropBoxResizable={true}
            draggable={false}
            // minCropBoxHeight={400}
            // minCropBoxWidth={400}
            cropBoxMovable={true}
            viewMode={1}
          />
        </div>
      )}
      {image ? (
        <button
          onClick={handleCrop}
          className="rounded-[8px] px-4 py-2 mt-6 bg-[#2e86de]"
        >
          Crop
        </button>
      ) : null}

      {!isHighResolution && (
        <div style={{ color: "red", marginTop: "10px" }}>
          <p>Please upload an image with a minimum size of 720px x 1080px.</p>
        </div>
      )}
    </div>
  );
};

export default App;
