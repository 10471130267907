import React, { useEffect, useState } from "react";
import Popup from "../layout/Popup";

const SongForm = ({
  handleAdd,
  handleEdit,
  editSong,
  handleClose,
  playlistId,
  status,
}) => {
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    editSong
      ? handleEdit(
          {
            title,
            artist,
            status: editSong?.status,
          },
          editSong.songId
        )
      : handleAdd({
          title,
          artist,
          playlistId,
          status: status,
        });
    handleClose();
  };

  useEffect(() => {
    if (editSong) {
      setTitle(editSong.title);
      setArtist(editSong.artist);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup dark={true} popupstyles="p-0 m-0" setFunc={handleClose}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col  gap-5  h-[100%]  sm:pb-5 pb-10"
      >
        {/* <h1 className="text-3xl font-bold">{editSong ? "Edit" : "Add"} Song</h1> */}
        <h1 className="text-4xl font-bold bg-black text-white w-full px-5 pt-12 pb-4 shadow-md border border-[#303947]">
          Add Song
        </h1>

        <div className="flex flex-col gap-4 flex-1  w-full p-6">
          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="title">Title</label>
            <input
              placeholder="Title"
              name="title"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
              required
            />
          </div>
          {/* <input
          className="border-[1px] border-pink rounded px-3 py-2 w-full placeholder:text-pink text-pink outline-none font-bold"
          placeholder="Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        /> */}
          {/* <input
          className="border-[1px] border-pink rounded px-3 py-2 w-full placeholder:text-pink text-pink outline-none font-bold"
          placeholder="Artist"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
        /> */}

          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="title">Artist</label>
            <input
              placeholder="Artists"
              name="title"
              id="title"
              value={artist}
              onChange={(e) => setArtist(e.target.value)}
              className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
              required
            />
          </div>
        </div>
        {/* <div className="flex gap-3"> */}

        <button
          type="submit"
          className="border-red  bg-red text-white border-[3px] px-6 py-2 font-bold rounded mx-6"
        >
          Add
        </button>
        {/* <button
          type="submit"
          className="border-[1px] border-red text-red font-bold py-2 w-[100px] rounded-full"
        >
          Save
        </button> */}
        {/* <button
            onClick={handleClose}
            type="button"
            className="border-[1px] border-black text-black font-bold py-2 w-[100px] rounded-full"
          >
            Cancel
          </button> */}
        {/* </div> */}
      </form>
    </Popup>
  );
};

export default SongForm;
