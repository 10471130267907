import { makeAutoObservable } from "mobx";

class LoadingStore {
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (value) => (this.isLoading = value);
}

const loadingStore = new LoadingStore();
export default loadingStore;
