import { BASE_URL } from "../constants";
import axios from "axios";
import authStore from "../store/auth";
import loadingStore from "../store/loading";

const get_query = async (endpoint, props) => {
  const {
    state: { isAuthenticated },
    logout,
  } = authStore;

  if (isAuthenticated) {
    loadingStore.setLoading(true);

    let storedDate = localStorage.getItem("x-auth-date");

    // Get the current date
    let currentDate = new Date();

    let timeDifference =
      currentDate?.getTime() - new Date(storedDate)?.getTime();

    let oneDayInMillis = 1000 * 60 * 60 * 24;

    if (timeDifference > oneDayInMillis) {
      logout();
      window.location.href = "/";
    } else {
      console.log("Less than one day.");
    }
  }
  try {
    const res = await axios.get(BASE_URL + endpoint);
    return { code: 200, data: res.data };
  } catch (error) {
    return { code: error?.response?.status, data: error?.response?.data };
  } finally {
    loadingStore.setLoading(false);
  }
};

export default get_query;
