import React, { useEffect, useState } from "react";
import songStore from "../../store/song";
import moment from "moment";

const PreviousRequestItem = ({
  request: { songId, createdAt, memo, time, status, name, date, Song },
  // request,
  index,
}) => {
  // const { getSongById } = songStore;
  // const [song, setSong] = useState({});

  // useEffect(() => {
  //   getSongById(songId).then((res) => setSong(res));
  // }, [songId]);

  let newdate = createdAt.slice(0, 10).split("-");

  let newtime = new Date(createdAt);
  const hours = newtime.getHours();
  const minutes = newtime.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return (
    <div className="flex sm:flex-row flex-col  sm:items-center gap-5 mb-5 p-3 border rounded border-[#303947] bg-[#22252A] drop-shadow-md sm:text-lg xs:text-xs text-[8px]">
      <div className="flex-1 flex gap-5 ">
        <div className="flex-1">
          <p className="sm:text-lg xs:text-xs text-[8px]">
            {Song ? (
              <>
                <span className="font-bold ">{Song?.title}</span>
                {Song?.artist && <span> by {Song?.artist}</span>}
              </>
            ) : (
              <span className="italic text-gray line-through">
                The Song is Deleted
              </span>
            )}
          </p>
          <p className="text-[#797A7D]">{name}</p>
          <p className="text-[#797A7D]">{memo}</p>
        </div>
      </div>
      <div className="flex gap-5 sm:justify-start justify-between items-center">
        <p className="flex items-center gap-5">
          <span>{`${newdate[1]}/${newdate[2]}/${newdate[0]}`}</span>

          <span>{`${formattedHours}:${minutes
            .toString()
            .padStart(2, "0")} ${ampm}`}</span>
        </p>
        <p className="border-[#303947] border bg-gray h-16"></p>
        {status === "completed" && (
          <p className="text-black bg-[#5BCEA3] sm:px-16  xs:px-6 px-4 py-2 font-bold inline-block rounded">
            Played
          </p>
        )}
        {status === "skipped" && (
          <p className="text-white bg-red sm:px-16  xs:px-6 px-4 py-2 font-bold inline-block rounded">
            Ignored
          </p>
        )}
      </div>
    </div>
  );
};

export default PreviousRequestItem;
