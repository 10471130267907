export function toDataURL(url) {
  return new Promise((resolve, reject) => {
    // Add a timestamp or random number to the URL to prevent caching
    const cacheBustedUrl = url + "?t=" + new Date().getTime();

    fetch(cacheBustedUrl)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error("Network response was not ok.");
      })
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data); // Resolve the Promise with the data URL (base64 string)
        };
        reader.onerror = function () {
          reject(new Error("Error reading blob as data URL"));
        };
        reader.readAsDataURL(blob); // Convert the blob to a base64 string
      })
      .catch((error) => {
        reject(error); // Reject the Promise if there's an error
      });
  });
}
