import React, { useState } from "react";

import { PLACEHOLDER_IMAGE } from "../../constants";
import { BiEdit, BiCheck } from "react-icons/bi";
import { toast } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FaExternalLinkAlt } from "react-icons/fa";
import UpdatePassword from "./UpdatePassword";
import Popup from "../layout/Popup";

const General = ({
  formData,
  onChange,
  setSelected,
  handleImageUpload,
  existingImage,
  handleResetPassword,
  onSubmit,
}) => {
  const [updatePassword, setUpdatePassword] = useState(false);

  const updateClose = () => {
    setUpdatePassword(false);
  };
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [newPassword, setNewPassword] = useState("");
  // const [confirmPassword, setconfirmPassword] = useState("");

  const [passwordObj, setPasswordObj] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  // const [selectedImage, setSelectedImage] = useState(null);
  // const handleSelectImage = async (e) => {
  //   setSelectedImage(e.target.files[0]);

  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);

  //   handleImageUpload(formData);
  // };

  const resetPassword = () => {
    // alert(JSON.stringify(passwordObj));
    if (
      passwordObj?.password.length < 8 ||
      passwordObj?.newPassword.length < 8
    ) {
      toast.error("Password should be atleast 8 characters long");
      setUpdatePassword(false);
      setShowPassword(false);
      setPasswordObj({
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      return;
    }

    if (passwordObj?.password === passwordObj?.newPassword) {
      toast.error("New Password must not be equal to old One !");
      setUpdatePassword(false);
      setShowPassword(false);
      setPasswordObj({
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      return;
    }
    if (passwordObj?.newPassword !== passwordObj?.confirmPassword) {
      toast.error("New Password must be equals to Confirm Password");
      setUpdatePassword(false);
      setShowPassword(false);
      setPasswordObj({
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      return;
    }
    handleResetPassword({
      password: passwordObj?.newPassword,
      confirmPassword: passwordObj?.newPassword,
    });
    setUpdatePassword(false);
    setShowPassword(false);
    setPasswordObj({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const cancelUpdatePassword = () => {
    setUpdatePassword(false);
    setShowPassword(false);
  };

  return (
    <section className="py-2 flex justify-center flex-col min-h-[90vh]">
      <section className="flex flex-col gap-4 border-[#303947] flex-1  bg-[#22252A] sm:p-4 p-2">
        <div className=" text-[12px] sm:text-[14px] flex flex-col gap-1">
          <label className="font-semibold">First Name:</label>
          <input
            type="text"
            name="firstName"
            placeholder="input first name"
            value={formData.firstName}
            onChange={onChange}
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className=" text-[12px] sm:text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Last Name:</label>
          <input
            type="text"
            name="lastName"
            placeholder="input last name"
            value={formData.lastName}
            onChange={onChange}
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className=" text-[12px] sm:text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Email:</label>
          <input
            type="text"
            name="email"
            placeholder="input email"
            value={formData.email}
            onChange={onChange}
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>
        <div className=" text-[12px] sm:text-[14px] flex flex-col gap-1">
          <label className="font-semibold">Number:</label>
          <input
            type="text"
            name="number"
            placeholder="input number"
            value={formData.number}
            onChange={onChange}
            className="border-gray bg-[#323338] rounded p-3 outline-none "
          />
        </div>

        <p
          className=" text-[14px] flex items-center gap-2 underline text-[#ECECEC] cursor-pointer"
          onClick={() => setUpdatePassword(true)}
        >
          <span>Change Password</span> <FaExternalLinkAlt color="#FB2E33" />
        </p>
      </section>

      {updatePassword && (
        <Popup dark={true} popupstyles="p-0 m-0" setFunc={updateClose}>
          <UpdatePassword
            resetPassword={resetPassword}
            formData={passwordObj}
            setPasswordObj={setPasswordObj}
          />
        </Popup>
      )}

      <button
        className="bg-red w-[96%] mx-auto rounded p-1 text-[16px]"
        onClick={onSubmit}
      >
        Save
      </button>
    </section>
  );
};

export default General;
