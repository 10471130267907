import React, { useState } from "react";
import authStore from "../../store/auth";
import { Link } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const { login } = authStore;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    login({ email: email.toLowerCase(), password });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="relative w-full h-full flex gap-3 items-center justify-center flex-col sm:pb-5 pb-10 "
    >
      <div className="absolute top-0 left-0">
        <img
          alt="logo"
          className="w-[140px] object-cover"
          src={process.env.PUBLIC_URL + "/assets/icons/logo-xl.png"}
        />
      </div>
      <h1 className="text-4xl font-bold underline text-white sm:mt-14 mt-8">
        Login
      </h1>

      <div className="flex gap-2 flex-col mt-3 text-xl  sm:w-[80%] w-[92%]">
        <label className="text-white text-sm ">Email: </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border-[1px] border-zinc-500 bg-[#272727] text-white text-base rounded-md px-4 py-1 "
        />
      </div>

      <div className="flex gap-2 flex-col mt-3 text-xl  sm:w-[80%] w-[92%]">
        <label className="text-white text-sm ">Password: </label>
        <div className="w-full relative">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border-[1px] border-[#616161] bg-[#272727] text-white text-base rounded-md px-4 py-1"
            // className="w-full border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
          />
          <button
            className="absolute right-[10px] top-[8px]"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FiEyeOff className="text-gray" />
            ) : (
              <FiEye className="text-white" />
            )}
          </button>
        </div>
      </div>

      <button className="text-white text-sm underline text-end  sm:w-[80%] w-[92%]">
        <Link to="/forgot-password">Forgot Password?</Link>
      </button>

      <button
        type="submit"
        className="my-10 border-1 border-red text-1xl py-2  sm:w-[80%] w-[92%] bg-red rounded-md text-white"
      >
        Log in
      </button>
      <div className="absolute bottom-0">
        <label className="text-white text-sm ">
          Don't have an account?{" "}
          <button className="text-red font-bold">
            <Link to="/register">Sign Up</Link>
          </button>{" "}
        </label>
      </div>
    </form>
  );
};

export default Login;
