import React, { useState } from "react";
import authStore from "../../store/auth";

const ForgotPassword = () => {
  const { forgotPassword } = authStore;

  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ email });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="relative w-full h-full flex gap-3 items-center justify-center flex-col">
      <h1 className="text-5xl font-bold sm:mt-14 mt-8">Forgot Password</h1>

      <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[400px] w-[90%]">
        <label>Email: </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
        />
      </div>

      <button
        type="submit"
        className="border-4 border-red text-2xl py-4 px-8 rounded-full">
        Submit
      </button>
    </form>
  );
};

export default ForgotPassword;
