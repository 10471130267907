import React from "react";
import SongsList from "../song/SongsList";

const RightSide = ({
  show,
  setShow,
  songs,
  playlist,
  searchSongText,
  filteredSongs,
  setSearchSongText,
  setAdd,
  isSubscribed,
}) => {
  return (
    <div className="bg-[#22252A] border-[#303947] border-2 rounded flex-3 h-[85vh] flex flex-col pb-3 flex-1 lg:col-span-7 col-span-12">
      {/* Upper Box  --- Search Bar Area*/}
      <div className="flex justify-between pb-3 items-center mb-4 p-3  border-[#303947] border-b-2">
        <h3 className="sm:text-2xl xs:text-lg text-sm font-semibold">
          Playlist
        </h3>
        <div className="flex items-center xs:gap-3 gap-1">
          <div className="bg-[#1E2126] flex xs:p-2 p-1 border-[#485261] border-2  lg:w-[220px] rounded-lg  xxl:w-[250px] sm:w-[200px]  w-[150px]  overflow-hidden">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/search-icon.svg`}
              height={20}
              width={20}
              className="mr-1"
            />
            <input
              type="text"
              className="flex-1 outline-none bg-[#1E2126] text-white sm:text-lg text-xs"
              placeholder="Search"
              value={searchSongText}
              onChange={(e) => setSearchSongText(e.target.value)}
            />
          </div>
          <button
            className={`sm:text-lg xs:text-xs text-[8px] sm:px-10 px-5 py-2  rounded-md  ${
              show === "selected"
                ? "bg-red text-white"
                : "text-[#5C5C5C] bg-[#323338]"
            } disabled:bg-[#323338] disabled:text-[#5C5C5C]`}
            onClick={() => {
              if (
                isSubscribed ||
                (!isSubscribed && filteredSongs.length < 20)
              ) {
                setAdd(true);
              }
            }}
            disabled={
              !isSubscribed && filteredSongs.length >= 20 ? true : false
            }
          >
            Add Song
          </button>
        </div>
      </div>

      {/* Songs Rendering area  */}
      <div className="flex-1 overflow-y-auto p-3 scrollbar-hide container-inner">
        {show === "selected" ? (
          <div className="flex flex-col gap-3 h-full">
            <p
              className=""
            >
              {playlist?.description}
            </p>

            {songs.length >= 1 && (
              <SongsList
                filterSongs={
                  searchSongText && playlist
                    ? filteredSongs.filter((x) =>
                        x.title
                          .toLowerCase()
                          .includes(searchSongText.toLowerCase())
                      )
                    : isSubscribed
                    ? filteredSongs
                    : filteredSongs.slice(0, 20)
                }
              />
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center h-full">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Playlist_left_side_01.svg`}
              className="h-[80%]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSide;
