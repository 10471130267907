import React, { Fragment, useState, useEffect } from "react";
import analyticsStore from "../../store/analytics";
import ExportCSVButton from "../layout/ExportCsvButton";

import { MdOutlineDateRange } from "react-icons/md";
import moment from "moment";
const Audience_Data_03 = () => {
  const [viewClicked, setViewClicked] = useState(1);

  const {
    state: { top_requesters },

    getTopRequesters,
  } = analyticsStore;

  const [date, setDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getTopRequesters();
  }, []);
  useEffect(() => {
    setIsOpen(false);
    getTopRequesters(
      moment.utc(date).format("YYYY-MM-DD"),
      moment.utc(new Date()).format("YYYY-MM-DD")
    );
  }, [date]);
  return (
    <section className="h-full flex justify-center w-full items-center min-h-full">
      <section className="mx-auto  xxl:w-[1090px]  xl:w-[900px] md:w-[700px] sm:w-[600px] w-[90%]  flex flex-col gap-3 text-white mt-8 items-center  border-[#485261]  px-4 backdrop-blur-11 opacity-1 py-4">
        <h3 className="sm:text-3xl text-xl font-semibold">
          Audience Participation
        </h3>

        <section className="w-full bg-[#22252A] backdrop-blur-11 backdrop-blur-[11px] bg-gray-900 bg-no-repeat bg-padding-box rounded-lg bg-opacity-10 border border-[#485261] custom-backdrop-filter">
          <section className="flex justify-between items-center border border-gray px-3 py-6 rounded-t">
            <h5 className="sm:text-2xl text-xs">Audience Members</h5>

            <p>Shows Attended</p>
            {/* <div className="sm:order-1 order-2 flex items-center  p-2 border-[#303947] border relative">
              <label
                htmlFor="datepicker"
                className="md:text-lg  sm:text-sm  xs:text-xs text-[8px]"
                onClick={toggleDropdown}
              >
                {date
                  ? moment.utc(date).format("DD/MM/YYYY")
                  : "Select a  Date"}
              </label>
              <MdOutlineDateRange
                className="sm:ml-2 inline-block self-center  cursor-pointer "
                onClick={toggleDropdown}
              />

              {isOpen && (
                <div
                  className="absolute -left-10 top-9 bg-white text-black
                flex flex-col  text-sm  rounded z-30
                "
                >
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      const today = new Date();
                      const before7Days = new Date(today);
                      setDate(before7Days.setDate(today.getDate() - 7));
                    }}
                  >
                    Last Week
                  </span>
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() =>
                      setDate(
                        new Date(
                          new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                        )
                      )
                    }
                  >
                    Last Month
                  </span>
                  <span
                    className="border-b border-gray py-2 px-4 cursor-pointer"
                    onClick={() => {
                      setDate(null);
                      setClicked("whole");
                    }}
                  >
                    Whole
                  </span>
                </div>
              )}
            </div> */}
          </section>
          <section className="flex pt-4 flex-col ">
            <div className="overflow-hidden max-h-[300px] p-3 h-[90vh]">
              {top_requesters.length > 10 ? (
                <Fragment>
                  <div className="overflow-y-auto max-h-[300px] pt-5 pb-10">
                    {top_requesters?.slice(0, viewClicked * 10).map((x, i) => (
                      <div
                        key={i}
                        className="border-[#485261]  border-b flex p-3 justify-between items-center backdrop-blur-[11px]"
                      >
                        <section className="flex items-start flex-col text-[14px]">
                          <p className="text-center">{x.name}</p>
                          <p className="text-center">{x.email}</p>
                        </section>
                        <h6 className="">{x.count}</h6>
                      </div>
                    ))}
                    {top_requesters.length > 0 && (
                      <button
                        className=" mt-2 text-sm bg-black bg-blur-[11px] border-2 border-gray p-1 rounded px-2 mx-auto"
                        onClick={() => {
                          if (viewClicked * 10 < top_requesters.length) {
                            setViewClicked((count) => count + 1);
                          }
                        }}
                      >
                        {viewClicked * 10 <= top_requesters.length - 1
                          ? "View More"
                          : "That's it for Now !"}
                      </button>
                    )}
                  </div>
                </Fragment>
              ) : (
                <p className="text-center">
                  You will see your biggest fans here after you have at least 10
                  requests. Stay tuned!
                </p>
              )}
            </div>
            {top_requesters.length > 50 && (
              <ExportCSVButton
                data={top_requesters}
                filename="exported_data"
                title="Export Top List"
                extraStyle="w-[40%] bg-red border-none rounded self-center mt-6"
              />
            )}
          </section>
        </section>
      </section>
    </section>
  );
};

export default Audience_Data_03;
