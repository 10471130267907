import React from "react";
import Item from "./Item";

const CampaignLeftSide = ({
  campaigns,
  setCampaign,
  setEditData,
  deleteCampaign,
  setShowForm,
  searchText,
  campaign,
  setSearchText,
  setDeletion,
}) => {
  return (
    <div className="bg-[#22252A] flex-2 border-[#303947] border-2 rounded h-[85vh] flex flex-col pb-2 lg:col-span-5 col-span-12">
      {/* Upper Box  --- Search Bar Area*/}
      <div className="flex justify-between items-center p-3 border-b-2 border-[#303947]">
        <h3 className="sm:text-2xl text-lg font-semibold text-white">
          Campaign
        </h3>
        <div className="bg-[#1E2126] flex xs:p-2 p-1 border-[#485261] border-2   rounded-lg  xxl:w-[250px] sm:w-[200px]  w-[150px] overflow-hidden">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/search-icon.svg`}
            height={20}
            width={20}
            className="mr-1"
          />
          <input
            type="text"
            className="flex-1 outline-none bg-[#1E2126] text-white sm:text-lg text-xs"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      {/* Campaign Rendering Area  */}
      <div className="flex-1 p-3 overflow-y-auto container-inner">
        {campaigns.length > 0 ? (
          <>
            {searchText.length > 0
              ? campaigns
                  .filter((x) =>
                    x.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((x, i) => (
                    <Item
                      data={x}
                      key={i}
                      setCampaign={setCampaign}
                      setEditData={setEditData}
                      deleteCampaign={deleteCampaign}
                    />
                  ))
              : campaigns.map((x, i) => (
                  <>
                    <Item
                      isRed={campaign?.name === x.name}
                      data={x}
                      key={i}
                      setDeletion={setDeletion}
                      setCampaign={setCampaign}
                      setEditData={setEditData}
                      deleteCampaign={deleteCampaign}
                    />
                  </>
                ))}
          </>
        ) : (
          <div className="h-full flex justify-center items-center font-primary">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/add_campaign.svg"}
            />
          </div>
        )}
      </div>

      {/* Add Campaign Button */}

      <button
        className="w-[96%] mx-auto bg-red p-2 rounded-md text-white"
        onClick={() => setShowForm(true)}
      >
        Add Campaign
      </button>
    </div>
  );
};

export default CampaignLeftSide;
