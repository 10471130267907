import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { RxCross1 } from "react-icons/rx";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const About_04 = ({ image }) => {
  // const images = [image, image, image, image, image, image];
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderOpen, setSliderOpen] = useState(false);

  let images = [
    process.env.PUBLIC_URL + "/assets/images/for_example_02.jpeg",
    process.env.PUBLIC_URL + "/assets/images/for_example_03.jpeg",
    process.env.PUBLIC_URL + "/assets/images/for_example_01.jpeg",
    process.env.PUBLIC_URL + "/assets/images/for_example_04.jpeg",
    process.env.PUBLIC_URL + "/assets/images/for_example_05.jpeg",
    process.env.PUBLIC_URL + "/assets/images/for_example_06.jpeg",
  ];
  const [slideNumber, setSlideNumber] = useState(0);
  const openSlider = (index) => {
    setSelectedImage(images[index]);
    setSliderOpen(true);
    setSlideNumber(index);
  };

  const closeSlider = () => {
    setSliderOpen(false);
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div
      className="h-full flex justify-center items-center w-full"
      style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      <div className="w-[94%] mx-auto text-white  flex flex-col gap-6">
        <h2 className="lg:text-4xl text-2xl font-[600]">For Example</h2>
        <ul className="grid grid-cols-12 gap-4 px-5">
          {images.map((img, index) => (
            <li
              className="sm:col-span-4 col-span-6 cursor-pointer"
              key={index}
              onClick={() => openSlider(index)}
            >
              <img
                src={img}
                className="w-full rounded-lg border-[#AEAEAE] border-2 bg-contain"
              />
              {/* h-32 lg:h-44 xxl:h-56 xxxl:h-68 */}
            </li>
          ))}
        </ul>
        {sliderOpen && (
          <div className="fixed top-0 w-full h-[80vh] my-auto left-0 right-0 bottom-0 z-[570000] bg-blur-[11px] flex justify-center items-center  ">
            <div
              className="relative sm:w-[80%] w-[100%] h-[80vh]"
              style={{ position: "relative", width: "80%", margin: "0px auto" }}
            >
              <p
                className="p-[15px] rounded-full cursor-pointer right-2 top-2 bg-gray  absolute z-[1000000]"
                onClick={() => setSliderOpen(false)}
              >
                <RxCross1 />
              </p>
              <Swiper
                initialSlide={slideNumber}
                direction={"horizontal"}
                // onSlideChange={handleSlideChange}
                // onSlideChangeTransitionEnd={handleSlideChangeBack}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-button-next", // Define next arrow element
                  prevEl: ".swiper-button-prev", // Define previous arrow element
                  disabledClass: "swiper-button-disabled", // Disabled class
                }}
                style={{
                  // Override direction to horizontal on smaller screens
                  ...(window.innerWidth < 768 && { direction: "horizontal" }),
                }}
                modules={[Pagination, Navigation]}
                className="m-0 h-[100%] w-[100%]  text-black p-0 relative "
              >
                <SwiperSlide>
                  <img
                    src={images[0]}
                    className=" rounded-lg border-[#AEAEAE] border-2 w-full"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={images[1]}
                    className=" rounded-lg border-[#AEAEAE] border-2 w-full"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={images[2]}
                    className=" rounded-lg border-[#AEAEAE] border-2 w-full"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={images[3]}
                    className=" rounded-lg border-[#AEAEAE] border-2 w-full"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={images[4]}
                    className=" rounded-lg border-[#AEAEAE] border-2  w-full"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={images[5]}
                    className=" rounded-lg border-[#AEAEAE] border-2  w-full"
                  />
                </SwiperSlide>
              </Swiper>

              {/* Arrow elements with icons */}
              <div className="absolute   z-[5002090382]  bottom-2 left-2   -rotate-90 ">
                <FaChevronUp
                  size={30}
                  className="swiper-button-prev"
                  onClick={() => setCurrentSlide((slide) => slide - 1)}
                />
              </div>
              <div className="absolute sm:bottom-2 z-[5002090382]   right-2 bottom-2 -rotate-90 ">
                <FaChevronDown
                  size={30}
                  className="swiper-button-next"
                  onClick={() => setCurrentSlide((slide) => slide + 1)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default About_04;
