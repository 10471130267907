import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import authStore from "../store/auth";
import { observer } from "mobx-react";

import Login from "../component/auth/Login";
import Register from "../component/auth/Register";
import ForgotPassword from "../component/auth/ForgotPassword";
import ResetPassword from "../component/auth/ResetPassword";
import ResendEmail from "../component/auth/ResendEmail";
import Popup from "../component/layout/Popup";
// import CheckEmail from "../component/auth/CheckEmail";

const Landing = ({ show }) => {
  const navigate = useNavigate();
  const {
    state: { isAuthenticated },
  } = authStore;

  const [showVerify, setShowVerify] = useState("");
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/playlist");
    }
  }, [isAuthenticated]);

  return (
    <div className="w-full sm:pt-0 pt-20 flex justify-center items-center bg-white min-h-screen">
      <img
        alt="background"
        src={process.env.PUBLIC_URL + "/assets/images/About_slider_bg_01.jpg"}
        className="w-full h-full top-0 left-0 object-cover absolute "
      />
      {(!show || show === "login") && (
        <div className="relative w-full h-screen flex gap-8 items-center justify-center flex-col">
          <img
            alt="logo"
            className="w-32 object-cover"
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
          />
          <section className="flex flex-col gap-4 text-center">
            <h1 className="text-5xl font-[100]">
              <span className="font-bold">Next</span>Quest
            </h1>
            <p className="text-xl text-center">
              The Bridge Between The Artist and Audience
            </p>
          </section>
        </div>
      )}
      {show === "login" && (
        <Popup
          dark={true}
          setFunc={() => {
            navigate("/");
          }}
        >
          <Login />
        </Popup>
      )}
      {show === "register" && (
        <Popup
          dark={true}
          setFunc={() => {
            navigate("/");
          }}
        >
          <Register
            setShow={setShowVerify}
            setFunc={() => {
              navigate("/");
            }}
          />
        </Popup>
      )}
      {/* {show === "verify" && (
        <Popup dark={true}>
          <CheckEmail
            setFunc={() => {
              navigate("/");
            }}
          />
        </Popup>
      )} */}
      {show === "resend-email" && (
        <Popup
          dark={true}
          setFunc={() => {
            navigate("/");
          }}
        >
          <ResendEmail
            setFunc={() => {
              navigate("/");
            }}
          />
        </Popup>
      )}
      {show === "forgot-password" && (
        <ForgotPassword
          setFunc={() => {
            navigate("/");
          }}
        />
      )}
      {show === "reset-password" && (
        <ResetPassword
          setFunc={() => {
            navigate("/");
          }}
        />
      )}
    </div>
  );
};

export default observer(Landing);
