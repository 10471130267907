import React, { useState } from "react";
import authStore from "../../store/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ResetPassword = () => {
  const { resetPassword } = authStore;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    const token = searchParams.get("token");
    resetPassword({ password, token }).then((res) => {
      res === 200 && navigate("/login");
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="relative w-full h-full flex gap-3 items-center justify-center flex-col"
    >
      <h1 className="text-5xl font-bold sm:mt-14 mt-8">Reset Password</h1>

      <div className="flex gap-2 flex-col mt-3 text-xl sm:w-[400px] w-[90%]">
        <label>New Password: </label>
        <div className="w-full relative">
          <input
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border-[1px] border-grey bg-transparent rounded-full px-4 py-1"
          />
          <button
            className="absolute right-[-30px] top-[10px]"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        </div>
      </div>

      <button
        type="submit"
        className="border-4 border-red text-2xl py-4 px-8 rounded-full"
      >
        Submit
      </button>
    </form>
  );
};

export default ResetPassword;
