import { BASE_URL } from "../constants";
import axios from "axios";
import loadingStore from "../store/loading";

const post_query = async (endpoint, data) => {
  loadingStore.setLoading(true);

  try {
    const res = await axios.post(BASE_URL + endpoint, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { code: 200, data: res.data };
  } catch (error) {
    return { code: error?.response?.status, data: error?.response?.data };
  } finally {
    loadingStore.setLoading(false);
  }
};

export default post_query;
