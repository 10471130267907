import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import { VscTrash } from "react-icons/vsc";
import SongForm from "./SongForm";
import songStore from "../../store/song";
import ToggleButton from "../ToggleButton";
import CenterPopup from "../layout/CenterPopup";

const SongItem = ({
  song,
  song: { songId, title, artist },
  showBorder,
  setSong,
}) => {
  const { editSong, deleteSong } = songStore;

  const [edit, setEdit] = useState(null);
  const [deletion, setDeletion] = useState(null);

  const handleEdit = (data, id) => {
    editSong(data, id);
  };

  const handleDelete = () => {
    console.log("Deletion ", songId);
    deleteSong(songId);
    setDeletion(null);
  };

  return (
    <div className="sm:text-[14px] text-xs shadow-lg bg-[#22252A] my-2 rounded border border-[#303947]  grid grid-cols-12 gap-3 items-center px-3">
      <p className="col-span-3 line-clamp-1">{song.title}</p>
      <p className="col-span-3">{song.artist}</p>

      <p class=" col-span-6 flex justify-end p-2  text-white  text-center gap-2">
        <img
          onClick={() => setEdit(song, song.songId)}
          src={`${process.env.PUBLIC_URL}/assets/icons/update-icon.svg`}
          height={36}
          width={36}
          className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
        />

        <img
          onClick={() => setDeletion(true)}
          src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
          height={36}
          width={36}
          className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
        />
      </p>

      {edit && (
        <SongForm
          editSong={edit}
          handleEdit={handleEdit}
          handleClose={() => setEdit(null)}
        />
      )}

      {deletion && (
        <CenterPopup dark={true}>
          <div className="flex flex-col justify-center items-center gap-5">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/delete-circle-icon.svg`}
              height={36}
              width={36}
              className=" border-[#5E6A7B] cursor-pointer "
            />
            <p>Are you sure you want to delete this Song ?</p>

            <div className="flex gap-3 items-center">
              <button
                className="px-6 py-2 rounded bg-[#636363]"
                onClick={() => setDeletion(null)}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 rounded bg-red"
                onClick={handleDelete}
              >
                Okay
              </button>
            </div>
          </div>
        </CenterPopup>
      )}
    </div>
  );
};

export default SongItem;

// shadow-lg bg-black opacity-60

// <div
//   className={`shadow-lg bg-[#22252A] flex justify-between py-5 p-2 my-3 border-[#303947] border rounded`}
// >
//   <div className="flex items-center gap-8">
//     <p className="text-2xl font-bold flex-1">{title}</p>
//     <p>{artist}</p>
//   </div>

//   <div class=" flex-2 text-white flex gap-1 items-center text-center">
//     <img
//       onClick={() => setEdit(song)}
//       src={`${process.env.PUBLIC_URL}/assets/icons/update-icon.svg`}
//       height={36}
//       width={36}
//       className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
//     />

//     <img
//       onClick={handleDelete}
//       src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
//       height={36}
//       width={36}
//       className="p-2 border rounded border-[#5E6A7B] cursor-pointer"
//     />
//   </div>
//   <div className="text-3xl flex gap-3">
//     <button onClick={() => setEdit(song)}>
//       <BiEdit />
//     </button>
//     <button onClick={handleDelete}>
//       <VscTrash />
//     </button>
//   </div>

//   {edit && (
//     <SongForm
//       editSong={edit}
//       handleEdit={handleEdit}
//       handleClose={() => setEdit(null)}
//     />
//   )}
// </div>
