import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authStore from "../../store/auth";

const ResendEmail = () => {
  const [searchParams] = useSearchParams();
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const { resendEmail } = authStore;

  const handleResendEmail = () => {
    const token = searchParams.get("token");
    resendEmail({ token: token });
  };

  useEffect(() => {
    setAlreadyRegistered(searchParams.get("alreadyRegistered"));
  }, []);

  return (
    <div className=" relative w-full h-full flex gap-3 items-center justify-center flex-col sm:pb-5 pb-10">
      {alreadyRegistered === "1" ? (
        <label className="text-xl mb-5 text-center">
          You are already registered! Please check your email to activate your
          account.
        </label>
      ) : (
        <section className="relative w-full h-full flex gap-3 items-center justify-center flex-col  ">
          <div className="absolute top-0 left-0">
            <img
              alt="logo"
              className="w-[140px] object-cover"
              src={process.env.PUBLIC_URL + "/assets/icons/logo-xl.png"}
            />
          </div>

          <div className="text-white sm:text-[14px] text-[12px]">
            Registration Successful! Please check your email to verify.
          </div>
          <button
            onClick={() => handleResendEmail()}
            type="button"
            className=" text-white bg-red sm:text-[14px] text-[12px] py-3 px-6 rounded"
          >
            Resend Email
          </button>
        </section>
      )}
    </div>
  );
};

export default ResendEmail;
